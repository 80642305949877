import React, { useState } from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import NavbarWithObserver from "../../NavBar/NavBar";
import Footer from "../../Footer/Footer";
// import { useLanguage } from '../../LanguageContext';
import { useLanguage } from '../../LanguageContext';
const Crypto = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const { language, setLanguage } = useLanguage();

  const handleSetLang = (lang) => {
    setLanguage(lang);    
  }
    
    const handleSetOpenDialog = () => {
        setOpenDialog(true);
    }
    const handleSetCloseDialog = () => {
        setOpenDialog(false);
    }
    return (
        <div className="about-us">
            <NavbarWithObserver openDialog={openDialog} handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog} handleChangeLanguage={handleSetLang} />

            <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
            <Typography variant="h4" gutterBottom>
                Crypto Currency
            </Typography>
            <Typography variant="body1" paragraph>
                Welcome to Divinests! We are a premier real estate company dedicated to revolutionizing property buying, selling, and investing. With a team of highly skilled professionals and deep expertise in the real estate market, we are committed to delivering exceptional services, innovative solutions, and personalized support tailored to your unique needs.
            </Typography>
            <Typography variant="body1" paragraph>
                Since our establishment in [Year], we have continually evolved to stay ahead of industry trends and customer expectations. Our mission is to provide high-quality real estate solutions that exceed expectations, while fostering long-lasting relationships with our clients. Whether you are purchasing your dream home, selling a property, or exploring investment opportunities, we are here to guide you every step of the way.
            </Typography>
            <Typography variant="body1" paragraph>
                At Divinests, we are proud to embrace the future of finance by supporting cryptocurrency payments. This innovative approach offers our clients greater flexibility, security, and convenience when conducting real estate transactions. By integrating cryptocurrency into our services, we aim to empower clients who value cutting-edge technology and seamless payment solutions.
            </Typography>
            <Typography variant="body1" paragraph>
                Our core values of innovation, integrity, and excellence drive us to lead the real estate industry with forward-thinking strategies and a passion for delivering results. With a deep understanding of market trends and a commitment to finding the perfect property, we strive to make your real estate journey smooth, efficient, and successful.
            </Typography>
            <Typography variant="body1" paragraph>
                Get in touch with us to learn more about how we can help you achieve your real estate goals—now with the added advantage of cryptocurrency payments!
            </Typography>

            </Paper>

            <Footer />
        </div>
    );
};

export default Crypto;
