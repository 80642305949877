import React, { useState, useEffect,useCallback } from "react";
import Navbar from "../../../NavBar/NavBar";
import Config from "../../../Services/Config";
import "./Billing.css";
import { Tabs, Tab, Typography, Box, Grid, Paper, Button, Modal } from '@mui/material';
import SnackbarComponent from "../../../SnackBar/SnackBar";
import TransactionHistory from "./TransactionHistory/TransactionHistory"
import PaymentMethodPopup from "./PaymentMethodPopup";
import AdvertisementList from "./AdvertisementList";
import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useLanguage } from "../../../LanguageContext";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px', // Rounded edges
  transition: 'transform 0.3s ease-out', // Animation for the transform property
};

function PaymentMethodsComponent() {
  const [dialogContent, setDialogContent] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [lastFourDigits,setlastFourDigits]=useState()
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [addPaymentOpen, setPaymentOpen] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [price] = useState(100);
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [duration, setDuration] = useState('');
  const [start_date, setstart_date] = useState('');
  const [endDate, setEndDate] = useState('');
  const { language, setLanguage } = useLanguage();

  const handleSetLang = (lang) => {
    setLanguage(lang);    
  }
  const handleSetOpenDialog = () => {
    setOpenDialog(true);
  }
  const handleSetCloseDialog = () => {
    setOpenDialog(false);
  }

  const [subscription, setSubscription] = useState([]);

  const [priorityProperties, setPriorityProperties] = useState([]);


  useEffect(() => {
      const fetchPriorityProperties = async () => {
          try {
              const data = await Config.getOwnerProperties();
              setPriorityProperties(data.data.properties);
          } catch (error) {
              console.error('Error fetching priority properties:', error);
          }
      };
  
      fetchPriorityProperties();
  }, [])
  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const user = await Config.getUserDetails();
        if (user.data.id) {
          const id = user.data.id;
          const response = await Config.getSubscriptionsByUser(id);
          setSubscription(response.data); // Directly setting the array
          console.log(response.data, "Fetched subscription data");
        }
      } catch (error) {
        console.error('Error fetching subscription data:', error);
      }
    };
  
    fetchSubscriptionData();
  }, []);
  
  const handleAddPaymentOpen = () => {
    setPaymentOpen(true);
  };

  const handleAddPaymentClose = () => {
    setPaymentOpen(false);
  };
  const handleClickSubscribe = () => {
      setCardNumber(`XXXX-XXXX-XXXX-${lastFourDigits}`);
      setOpenDialog(true);
  
    setOpenDialog(true);
  };
    const handlestart_dateChange = (event) => {
    const dateValue = event.target.value;

    if (dateValue) {
      setstart_date(dateValue); // Update the start date immediately

      const date = new Date(dateValue);
      if (!isNaN(date)) {
        const end = new Date(date);
        end.setFullYear(end.getFullYear() + 1); // Add one year to the start date
        setEndDate(end.toISOString().split('T')[0]); // Update end date in 'YYYY-MM-DD' format
      }
    }
  };
  const formatDate = (date) => {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${month}/${year}`;
  };

  // Simulate fetching payment methods (replace with actual fetch call)
  useEffect(() => {
    const fetchCardsData = async () => {
      try {
        const user = await Config.getUserDetails();
        if (user.data.id) {
          const id = user.data.id;
        const data = await Config.getPaymentMethods(id);
        console.log('Payment Methods:', data); // Log the data received from the API
        setPaymentMethods(data);
        setlastFourDigits(data[0].last_four_digits)
        console.log(paymentMethods)
        }
      } catch (error) {
        console.error('Error fetching payment methods:', error);
      }
    };

    const fetchTransactionData = async () => {
      try {
        const data = await Config.getTransactionHistory();
        console.log(data.data);
        setTransactionHistory(data.data);
      } catch (error) {
        console.error('Error fetching transaction history:', error);
      }
    };
    

    fetchTransactionData();
    fetchCardsData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  async function handleRemoveClickPopup(cardId) {
    handleOpen()
    setSelectedCardId(cardId)
  }

  async function handleRemoveClickClose(cardId) {
    handleClose()
    setSelectedCardId(cardId)
  }

  async function handleRemoveClick() {
    try {
      const result = await Config.removeCard(selectedCardId);
      if (result.success) {
        setOpenSnackbar(true);
        setSnackbarMessage(result.message);
        setSnackbarSeverity("success");
        window.location.reload()
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(result.message);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  }
  const handleClickPriority = (property) => {
    console.log(property)
    setCardNumber(`XXXX-XXXX-XXXX-${lastFourDigits}`);
    setSelectedProperty(property);
    console.log(selectedProperty)
    setOpenDialog(true);
    setDuration(''); // Reset duration
  };


  const calculateTotalCost = (price, duration) => {
    if (!duration || isNaN(duration) || duration <= 0) return 0;
    return price * duration;
  };
  
  function getCardBrandLogo(brand) {
    // const brandLower = brand.toLowerCase();
    // console.log(brand)
    const basePath = '/assets/logos'; // Adjust based on where you store your images
    const logoPaths = {
      visa: `/visa.svg`,
      mastercard: `/mastercard.svg`,
      amex: `/american-express.svg`,
      diners: `/diners-club.svg`,
      discover: `/discover.svg`,
      jcb: `/jcb.svg`,
      unionpay: `/unionpay.png`,


      // Add other brands as needed
    };
    return logoPaths[brand] || `${basePath}/default.png`; // Default logo if brand not found
  }
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleConfirmPriority = async () => {
    try{
  const user = await Config.getUserDetails();
  if (user.data.id) {
    const id = user.data.id;
    // const response=await Config.createPriority(selectedProperty.id,id,calculateTotalCost(50, duration),duration)
  }
}catch (error) {
  console.error("Error fetching profile data:", error);
}

    handleCloseDialog();
  };
  const handleSubmit = async () => {
    try {
      const user = await Config.getUserDetails();
      if (user.data.id) {
      const user_id = user.data.id;
    const subscriptionData = {
      user_id,
      start_date,
      price
    };
    console.log(subscriptionData)
      await Config.createSubscription(subscriptionData);
      // Handle success (e.g., show a success message, close the dialog)
  }
      handleCloseDialog();
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error('Error creating subscription:', error);
    }
  };

  return (
    <div>
      <Navbar openDialog={openDialog} handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog} handleChangeLanguage={handleSetLang} />
      <div className="myContainer">
        <div className="myContainer-item">
          <Box sx={{ borderBottom: 1, borderColor: 'divider',display:'flex',justifyContent: 'space-between' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label="Payment Methods" />
              <Tab label="Advertisements" />
              <Tab label="Subscription" /> 
              <Tab label="Transaction History" />
              <Tab label="Priority Properties" />

            </Tabs>

            <Button variant="contained" color="primary" onClick={handleAddPaymentOpen}>
              Add Payment
            </Button>

          </Box>
          <TabPanel value={tabValue} index={0}>
  {paymentMethods && paymentMethods.length > 0 ? (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Payment Method Details
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell><Typography variant="body1" fontWeight="bold">Card Name</Typography></TableCell>
              <TableCell><Typography variant="body1" fontWeight="bold">lasr 4 digits Card Number</Typography></TableCell>
              <TableCell><Typography variant="body1" fontWeight="bold">Expiry Date</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentMethods.map((paymentMethod, index) => (
              <TableRow key={index}>
                <TableCell><Typography variant="body1">{paymentMethod.name || 'N/A'}</Typography></TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {paymentMethod.last_four_digits 
                      ? `XXXX-XXXX-XXXX-${paymentMethod.last_four_digits}`
                      : 'N/A'
                    }
                  </Typography>
                </TableCell>

<TableCell>
  <Typography variant="body1">
    {paymentMethod.expiry_date 
      ? (() => {
          try {
            const date = new Date(paymentMethod.expiry_date);
            if (isNaN(date.getTime())) {
              throw new Error('Invalid date');
            }
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year
            return `${month}-${year}`;
          } catch (error) {
            console.error('Error formatting expiry date:', error);
            return 'N/A';
          }
        })()
      : 'N/A'
    }
  </Typography>
</TableCell>


              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  ) : (
    <Typography variant="body1">No data available</Typography>
  )}
</TabPanel>

      <TabPanel value={tabValue} index={1}>
            <AdvertisementList
            last_fourdigit={lastFourDigits}
             />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
          {subscription && subscription.length > 0 ? (
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Subscription Details
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="body1" fontWeight="bold">Start Date</Typography></TableCell>
                  <TableCell><Typography variant="body1" fontWeight="bold">End Date</Typography></TableCell>
                  <TableCell><Typography variant="body1" fontWeight="bold">Price</Typography></TableCell>
                  <TableCell><Typography variant="body1" fontWeight="bold">Status</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscription.map((sub, index) => (
                  <TableRow key={index}>
                    <TableCell><Typography variant="body1">{sub.start_date || 'N/A'}</Typography></TableCell>
                    <TableCell><Typography variant="body1">{sub.end_date || 'N/A'}</Typography></TableCell>
                    <TableCell><Typography variant="body1">${sub.price || 'N/A'}</Typography></TableCell>
                    <TableCell>
                      <Typography variant="body1" sx={{ color: sub.is_active ? 'green' : 'red', fontWeight: 'bold' }}>
                        {sub.is_active ? 'Active' : 'Inactive'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Typography variant="body1">No data available</Typography>
      )}
      <Button onClick={handleClickSubscribe} variant="contained" color="primary">
        Subscribe
      </Button>
<Dialog open={openDialog} onClose={handleCloseDialog}>
  <DialogTitle>Subscription Details</DialogTitle>
  <DialogContent>
<TextField
        label="Start Date"
        type="date"
         margin="normal"
         fullWidth
        InputLabelProps={{ shrink: true }}
        value={start_date}
        onChange={handlestart_dateChange}  // Update both start and end dates on change
      />
    <TextField
      label="End Date"
      value={endDate}
      fullWidth
      margin="normal"
      InputProps={{ readOnly: true }}
    />
    <TextField
      label="Card Number"
      value={cardNumber}
      fullWidth
      margin="normal"
      InputProps={{ readOnly: true }}
    />
    <TextField
      label="Price"
      value={price}
      fullWidth
      margin="normal"
      InputProps={{ readOnly: true }}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDialog} color="primary">
      Cancel
    </Button>
    <Button onClick={handleSubmit} color="primary">
      Confirm Subscription
    </Button>
  </DialogActions>
</Dialog>

</TabPanel>


          <TabPanel value={tabValue} index={3}>
            <TransactionHistory data={transactionHistory} />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6" gutterBottom>
          Priority Properties
        </Typography>
        {priorityProperties.length === 0 ? (
          <Typography variant="body1" color="textSecondary">
            No data available or history.
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Short Description</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {priorityProperties.map((property) => (
                  <TableRow key={property.id}>
                    <TableCell>{property.name}</TableCell>
                    <TableCell>{property.short_description}</TableCell>
                    <TableCell>{property.price} {property.currency}</TableCell>
                    <TableCell>
                      {property.priority === 0 ? (
                        <Button variant="contained" color="primary" onClick={() => handleClickPriority(property)}>
                          Add Priority
                        </Button>
                      ) : (
                        <Typography color="textSecondary">Active</Typography>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Set Priority</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Price per month: 50 USD
          </Typography>
          <TextField
            label="Monthly Rent Duration (months)"
            type="number"
            margin="normal"
            fullWidth
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            inputProps={{ min: 1, step: 1 }}
          />
          <TextField
            label="Card Number"
            type="text"
            margin="normal"
            fullWidth
            value={cardNumber}
          />
          {duration && !isNaN(duration) && duration > 0 && (
            <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2 }}>
              Total Cost: {calculateTotalCost(50, duration)} USD
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmPriority} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </TabPanel>

        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirmation
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to remove this card?
            </Typography>
            <div style={{ marginLeft: 'auto', marginTop: '20px' }}>
              <Button variant="text" sx={{ color: 'grey', marginRight: '5px' }} onClick={handleRemoveClickClose}>Cancel</Button>
              <Button variant="text" color="primary" onClick={handleRemoveClick}>Yes, Remove</Button>
            </div>
          </div>
        </Box>
      </Modal>


      

      <SnackbarComponent
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <PaymentMethodPopup isOpen={addPaymentOpen} onClose={handleAddPaymentClose}/>


    </div>

    


  );


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
}

export default PaymentMethodsComponent;
