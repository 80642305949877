import { useAnimations, useGLTF } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import Config, { PYTHON_URL } from "../Services/Config";
// import { TextureLoader } from "three";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export function Avatar(props) {
  let { onLoad , latestMsg, isWidgetOpen ,  cameraControls , taganimation} = props; // Receiving isWidgetOpen prop


  // const { nodes, materials } = useGLTF("/models/64f1a714fe61576b46f27ca2.glb");
  useEffect(() => {
    if (cameraControls.current) {
      
      // This will ensure the camera is set as per your desired lookAt on Avatar load
      // cameraControls.current.setLookAt(0, 2, 5, 0, 1.5, 0);
      // cameraControls.current.setLookAt(0, 0, 0, 0, 0, 0);
    }else{
    }
  }, [cameraControls]);

  useEffect(() => {
    // Simulate avatar loading
    const loadAvatar = setTimeout(() => {
      if (onLoad) onLoad(); // Trigger the onLoad callback
    }, 1000); // Simulate 1-second loading time

    return () => clearTimeout(loadAvatar); // Cleanup timeout
  }, [onLoad]);
  
  //const { nodes, materials } = useGLTF(`/models/64f1a714fe61576b46f27ca2.glb?v=${version}`, (error) => {
  const { nodes, materials } = useGLTF(`/models/char4.glb`, (error) => {
    console.error("Failed to load model", error);
  });

  // const gltf = useGLTF(`/models/char1.glb`, (error) => {
  //   console.error("Failed to load model", error);
  // });
  // Load the model without textures
  // const nodes = useLoader(GLTFLoader, "/models/char2.glb");

  const { animations } = useGLTF(`/models/animations4.glb`);
  // const { animations } = useGLTF(`/models/talking.glb`);
  const group = useRef();
  const { actions, mixer } = useAnimations(animations, group);
  const [animation, setAnimation] = useState(
    animations.find((a) => a.name === "Idle") ? "Idle" : animations[0]?.name || ""
    // "waving"
  );
  // const [animation, setAnimation] = useState(
  //   animations.length > 0 ? animations[0].name : "" // Set the first animation's name if available
  // );

  const [scale, setScale] = useState(1);
  
  
  useEffect(() => {
    if (actions[animation]) {
      const action = actions[animation];
      action.reset().fadeIn(0.5).play();
      
      return () => {
        // action.fadeOut(0.5);
        actions[animation]?.fadeOut(0.5);

      };
    }
  }, [animation, actions]);

  
  // Handle window resizing
  useEffect(() => {
    
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      // Adjust scale based on window size but within a defined range
      const minScale = 0.5; // Minimum scale
      const maxScale = 1.5; // Maximum scale
      const idealWidth = 1200; // Width to base the scaling on
      const idealHeight = 800; // Height to base the scaling on

      const newScale = Math.max(
        minScale,
        Math.min(
          maxScale,
          Math.min(width / idealWidth, height / idealHeight)
        )
      );
      setScale(newScale);
    };

    // Set initial scale
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const playAudio =  () => {
    
      if (taganimation) {
        console.log("animations",animations);
        
        console.log("taganimation",taganimation);

        try {
          // const tag1 = await fetch(`http://127.0.0.1:5000/tag/${encodeURIComponent(latestMsg)}`);
          // const tag1 = await fetch(`${PYTHON_URL}tag/${encodeURIComponent(latestMsg)}`);
          // const resultText = await tag1.text();
          // const responseData = JSON.parse(resultText);
          // const tag = responseData.tag;
          // console.log("animations",animations);
          setAnimation(taganimation);
          // setAnimation("waving");

          // setAnimation("sad");
          // setAnimation("pointing");
          // setAnimation("talking_2");
          // setAnimation("waving");
          // setAnimation("talking_cc");
          // setAnimation("talking_cc");
          // if (tag !== 'failed') {
          //   setAnimation(tag);
          // } else {
          //   if (!latestMsg.toLowerCase().includes('rent') && !latestMsg.toLowerCase().includes('buy')) {
          //     setAnimation("sad");
          //     // setAnimation("Confused2");
          //   } else {
          //     // setAnimation("PointingBehind");
          //     setAnimation("pointing");
          //   }
          // }

          setTimeout(() => {
            setAnimation("Idle");
          }, 2000);

        } catch (error) {
          console.error('Error playing audio:', error);
        }
        
        // try {
        //   // const tag1 = await fetch(`http://127.0.0.1:5000/tag/${encodeURIComponent(latestMsg)}`);
        //   const tag1 = await fetch(`${PYTHON_URL}tag/${encodeURIComponent(latestMsg)}`);
        //   const resultText = await tag1.text();
        //   const responseData = JSON.parse(resultText);
        //   const tag = responseData.tag;
        //   console.log("animations",animations);
          
        //   // setAnimation("waving");

        //   // setAnimation("sad");
        //   // setAnimation("pointing");
        //   // setAnimation("talking_2");
        //   // setAnimation("waving");
        //   // setAnimation("talking_cc");
        //   // setAnimation("talking_cc");
        //   if (tag !== 'failed') {
        //     setAnimation(tag);
        //   } else {
        //     if (!latestMsg.toLowerCase().includes('rent') && !latestMsg.toLowerCase().includes('buy')) {
        //       setAnimation("sad");
        //       // setAnimation("Confused2");
        //     } else {
        //       // setAnimation("PointingBehind");
        //       setAnimation("pointing");
        //     }
        //   }

        //   setTimeout(() => {
        //     setAnimation("Idle");
        //   }, 2000);

        // } catch (error) {
        //   console.error('Error playing audio:', error);
        // }
      }
    };

    playAudio();

    return () => {
      // Any necessary cleanup
    };
  }, [taganimation,latestMsg, isWidgetOpen]); // Dependency on isWidgetOpen
  
  // useEffect(() => {
  //   setAnimation("waving");
  // }, []); // Empty dependency array ensures this runs only once
  useEffect(() => {
    if (actions[animation]) {
      try {
        const action = actions[animation];
        action.reset().fadeIn(mixer.stats.actions.inUse === 0 ? 0 : 0.5).play();

        return () => {
          action.fadeOut(0.5);
        };
      } catch (error) {
        console.error('Error setting up animation:', error);
      }
    } else {
      console.warn(`Animation action "${animation}" is not defined.`);
    }
  }, [animation, actions, mixer]);

  // return (
  //   <group ref={group}  {...props} dispose={null} scale={[scale, scale, scale]} position={[0, 0, 0]} >
  //     <primitive object={nodes.waving} />
  //     {gltf.scene.children.map((child, index) => (
  //       <mesh
  //         key={index}
  //         geometry={child.geometry}
  //         position={child.position}
  //         rotation={child.rotation}
  //         scale={child.scale}
  //       >
  //         {/* Apply textures */}
  //         <meshStandardMaterial map={texture} normalMap={texture} />
  //       </mesh>
  //     ))}
  //   </group>
  // );

  return (
    <group {...props} dispose={null} ref={group} scale={[scale, scale, scale]} position={[0, 0, 0]}>
      {/* <primitive object={nodes.Hips} /> */}
      <primitive object={nodes.waving} />
        {/* {gltf.scene.children.map((child, index) => (
          <mesh
            key={index}
            geometry={child.geometry}
            position={child.position}
            rotation={child.rotation}
            scale={child.scale}
          >
         
            <meshStandardMaterial map={texture} normalMap={texture} />
          </mesh>
        ))} */}
        
      {/* <skinnedMesh
        name="helloo"
        geometry={nodes.helloo.geometry}
        material={materials.hello}
        skeleton={nodes.helloo.skeleton}
      /> */}
      {/* <skinnedMesh
        name="Wolf3D_Body"
        geometry={nodes.Wolf3D_Body.geometry}
        material={materials.Wolf3D_Body}
        skeleton={nodes.Wolf3D_Body.skeleton}
      />
      <skinnedMesh
        name="Wolf3D_Outfit_Bottom"
        geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
        material={materials.Wolf3D_Outfit_Bottom}
        skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
      />
      <skinnedMesh
        name="Wolf3D_Outfit_Footwear"
        geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
        material={materials.Wolf3D_Outfit_Footwear}
        skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
      />
      <skinnedMesh
        name="Wolf3D_Outfit_Top"
        geometry={nodes.Wolf3D_Outfit_Top.geometry}
        material={materials.Wolf3D_Outfit_Top}
        skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
      />
      <skinnedMesh
        name="Wolf3D_Hair"
        geometry={nodes.Wolf3D_Hair.geometry}
        material={materials.Wolf3D_Hair}
        skeleton={nodes.Wolf3D_Hair.skeleton}
      />
      <skinnedMesh
        name="EyeLeft"
        geometry={nodes.EyeLeft.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeLeft.skeleton}
        morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
      />
      <skinnedMesh
        name="EyeRight"
        geometry={nodes.EyeRight.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeRight.skeleton}
        morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Head"
        geometry={nodes.Wolf3D_Head.geometry}
        material={materials.Wolf3D_Skin}
        skeleton={nodes.Wolf3D_Head.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Teeth"
        geometry={nodes.Wolf3D_Teeth.geometry}
        material={materials.Wolf3D_Teeth}
        skeleton={nodes.Wolf3D_Teeth.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
      /> */}
    </group>
  );
}

useGLTF.preload("/models/char4.glb");
useGLTF.preload("/models/animations4.glb");
