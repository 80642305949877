import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, Paper, Link } from '@mui/material';
import NavbarWithObserver from "../../NavBar/NavBar";
import Footer from "../../Footer/Footer";
import { useLanguage } from '../../LanguageContext';
import "./FacilityManagement.css"
const FacilityManagement = () => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const { language, setLanguage } = useLanguage();

    const handleSetLang = (lang) => {
        setLanguage(lang);
    }

    const handleSetOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleSetCloseDialog = () => {
        setOpenDialog(false);
    }

    const [facilities, setFacilities] = useState([]);
  const [formData, setFormData] = useState({
    facilityName: "",
    address: "",
    facilityType: "",
    totalArea: "",
    managerName: "",
    managerContact: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFacilities([...facilities, formData]);
    setFormData({
      facilityName: "",
      address: "",
      facilityType: "",
      totalArea: "",
      managerName: "",
      managerContact: "",
    });
  };

    return (
        <div className="about-us">
            <NavbarWithObserver 
                openDialog={openDialog} 
                handleSetOpenDialog={handleSetOpenDialog} 
                handleSetCloseDialog={handleSetCloseDialog} 
                handleChangeLanguage={handleSetLang} 
            />

<Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
<div style={{ padding: "20px" }}>
      <h1>Facility Management</h1>

      {/* Facility Form */}
      <form onSubmit={handleSubmit} style={{ marginBottom: "20px" }}>
        <h2>Add Facility</h2>
        <div>
          <label>
            Facility Name:
            <input
              type="text"
              name="facilityName"
              value={formData.facilityName}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Address:
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Facility Type:
            <select
              name="facilityType"
              value={formData.facilityType}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Type</option>
              <option value="Office">Office</option>
              <option value="Warehouse">Warehouse</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Residential">Residential</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Total Area (sq. ft/m²):
            <input
              type="number"
              name="totalArea"
              value={formData.totalArea}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Manager Name:
            <input
              type="text"
              name="managerName"
              value={formData.managerName}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Manager Contact:
            <input
              type="text"
              name="managerContact"
              value={formData.managerContact}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <button type="submit">Add Facility</button>
      </form>

      {/* Facility List */}
      {/* <h2>Facilities List</h2> */}
      {facilities.length > 0 ? (
        <table border="1" cellPadding="10">
          <thead>
            <tr>
              <th>Facility Name</th>
              <th>Address</th>
              <th>Type</th>
              <th>Total Area</th>
              <th>Manager Name</th>
              <th>Manager Contact</th>
            </tr>
          </thead>
          <tbody>
            {facilities.map((facility, index) => (
              <tr key={index}>
                <td>{facility.facilityName}</td>
                <td>{facility.address}</td>
                <td>{facility.facilityType}</td>
                <td>{facility.totalArea}</td>
                <td>{facility.managerName}</td>
                <td>{facility.managerContact}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p> </p>
      )}
    </div>

            </Paper>
            <Footer />
        </div>
    );
};

export default FacilityManagement;
