import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, Paper, Link } from '@mui/material';
import NavbarWithObserver from "../../NavBar/NavBar";
import Footer from "../../Footer/Footer";
import { useLanguage } from '../../LanguageContext';

const ContactUs = () => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const { language, setLanguage } = useLanguage();

    const handleSetLang = (lang) => {
        setLanguage(lang);
    }

    const handleSetOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleSetCloseDialog = () => {
        setOpenDialog(false);
    }

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here, you would typically send the form data to your server
        console.log('Form submitted:', formData);
        // Reset the form after submission
        setFormData({ name: '', email: '', message: '' });
    };

    return (
        <div className="about-us">
            <NavbarWithObserver 
                openDialog={openDialog} 
                handleSetOpenDialog={handleSetOpenDialog} 
                handleSetCloseDialog={handleSetCloseDialog} 
                handleChangeLanguage={handleSetLang} 
            />

            <Grid container justifyContent="center" style={{ padding: '20px' }}>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} style={{ padding: '20px' }}>
                        <Typography variant="h4" gutterBottom>
                            Contact Us
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                label="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                            />
                            <TextField
                                label="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                                type="email"
                            />
                            <TextField
                                label="Message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                                multiline
                                rows={4}
                            />
                            <Button type="submit" variant="contained" color="primary">
                                Send Message
                            </Button>
                        </form>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" style={{ padding: '20px' }}>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} style={{ padding: '20px' }}>
                        <Typography variant="h6" gutterBottom>
                            Our Contact Information:
                        </Typography>
                        <Typography paragraph>
                            <strong>Phone Number:</strong> +1 (305) 306-3683
                        </Typography>
                        <Typography paragraph>
                            <strong>Email:</strong> <Link href="mailto:info@divinests.com">info@divinests.com</Link>
                        </Typography>
                       
                        
                        
                    </Paper>
                </Grid>
            </Grid>

            <Footer />
        </div>
    );
};

export default ContactUs;
