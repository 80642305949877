import React, { useState, useEffect } from "react";
import Config from "../../../../Services/Config";
import Navbar from "../../../../NavBar/NavBar";
import "./ChangePassword.css";
import { FormControl, TextField, Grid, Button } from "@mui/material";
import SnackbarComponent from "../../../../SnackBar/SnackBar";


function ChangePassword() {

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  // const [imageFile, setImageFile] = useState(null);

  async function handleChangePassword() {
    // console.log(oldPassword);
    if(oldPassword.trim() !== "" && newPassword.trim() !== "" && confirmPassword.trim() !== ""){
      if (newPassword !== confirmPassword) {
        setOpenSnackbar(true);
        setSnackbarMessage("New password and Confirm password don't match");
        setSnackbarSeverity("error");
      } else {
        const result = await Config.updateUserPassword(oldPassword, newPassword);
        if (result.success) {
          // setProfileData(result.data);
          console.log(result.data);
          setOpenSnackbar(true);
          setSnackbarMessage(result.message);
          setSnackbarSeverity("success");
          setTimeout(() => {
            window.location.reload();
          }, 6000);
        } else {
          setOpenSnackbar(true);
          setSnackbarMessage(result.message);
          setSnackbarSeverity("error");
        }
      }
    } else {
      // if(){
        setOpenSnackbar(true);
        setSnackbarMessage("Fields Are Required");
        setSnackbarSeverity("error");
      // }
    }
    
  }

  return (
    <div>
      <h2>Change Password</h2>
      <form noValidate autoComplete="off" style={{ width: "100%" }}>
        <Grid
          container
          spacing={2}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {/* Grid item for the first TextField */}
          <Grid
            item
            xs={12}
            md={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <TextField
              className="profile-input"
              label="Old Password"
              variant="outlined"
              fullWidth
              placeholder="Max length : 20 char"
              // maxLength={20}
              required
              type="password"
              value={oldPassword}
              // onChange={(e) => setOldPassword(e.target.value)}
              onChange={(e) => {
                if (e.target.value.length <= 20) {
                  setOldPassword(e.target.value);
                }
              }}
            />
          </Grid>

          {/* Grid item for the second TextField */}
          <Grid
            item
            xs={12}
            md={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <TextField
              className="profile-input"
              label="New Password" // Assuming you want a Last Name field here
              variant="outlined"
              required
              placeholder="Max length : 20 char"
              // maxLength={20}
              fullWidth
              type="password"
              value={newPassword}
              // onChange={(e) => setNewPassword(e.target.value)}
              onChange={(e) => {
                if (e.target.value.length <= 20) {
                  setNewPassword(e.target.value);
                }
              }}
            />
          </Grid>

          {/* Grid item for the third TextField */}
          <Grid
            item
            xs={12}
            md={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <TextField
              className="profile-input"
              label="Confirm Password" // Assuming you want an Email field here
              variant="outlined"
              placeholder="Max length : 20 char"
              // maxLength={20}
              required
              fullWidth
              type="password"
              value={confirmPassword}
              // onChange={(e) => setConfirmPassword(e.target.value)}
              onChange={(e) => {
                if (e.target.value.length <= 20) {
                  setConfirmPassword(e.target.value);
                }
              }}
            />
          </Grid>
        </Grid>
      </form>

      {/* <img
        className="profile-img"
        src={imgUrl || `${API_URL}${profileData.profile_pic}`}
        alt="Profile"
      /> */}

      <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
        <Grid item xs={3} md={2} lg={1} style={{ margin: "20px" }}>
          <Button
            variant="contained"
            className="profile-submit"
            onClick={handleChangePassword}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <SnackbarComponent
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
}

export default ChangePassword;
