import React, { useState, useEffect } from "react";
import Config from "../../../../Services/Config";
import Navbar from "../../../../NavBar/NavBar";
// import "./Information.css";
import { FormControl, TextField, Grid, Button } from "@mui/material";
import SnackbarComponent from "../../../../SnackBar/SnackBar";



function Information({ addressData }) {
  const [value, setValue] = React.useState("");

  const [country, setCountry] = useState(addressData && addressData.country ? addressData.country : "");
  const [state, setState] = useState(addressData && addressData.state ? addressData.state : "");
  const [city, setCity] = useState(addressData && addressData.city ? addressData.city : "");
  const [street, setStreet] = useState(addressData && addressData.street ? addressData.street : "");
  const [zip, setZip] = useState(addressData && addressData.zip ? addressData.zip : "");


  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // Handlers for each input field
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleStreetChange = (event) => {
    setStreet(event.target.value);
  };

  const handleZipChange = (event) => {
    setZip(event.target.value);
  };



  // useEffect(() => {

  //   fetchProfileData();
  // }, []);

  async function handleAddressUpdate() {
    try {
      const result = await Config.updateAddressDetails(country, state, city, street, zip);
      if (result.success) {
        // setProfileData(result.data);
        console.log(result.data);
        setOpenSnackbar(true);
        setSnackbarMessage(result.message);
        setSnackbarSeverity("success");
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(result.message);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  }

  return (
    <div>
      {/* Wrapper with margin */}
      <h2>Address</h2>
      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                className="profile-input"
                label="Country"
                type="text"
                onChange={(e) => {
                  if (e.target.value.length <= 30) {
                    handleCountryChange(e);
                  }
                }}
                placeholder="Max length : 30 char"
                variant="outlined"
                value={country}
                // onChange={handleCountryChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                className="profile-input"
                label="State"
                type="text"
                onChange={(e) => {
                  if (e.target.value.length <= 30) {
                    handleStateChange(e);
                  }
                }}
                placeholder="Max length : 30 char"
                variant="outlined"
                value={state}
                // onChange={handleStateChange}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                className="profile-input"
                label="City"
                type="text"
                onChange={(e) => {
                  if (e.target.value.length <= 30) {
                    handleCityChange(e);
                  }
                }}
                placeholder="Max length : 30 char"
                variant="outlined"
                value={city}
                // onChange={handleCityChange}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                className="profile-input"
                label="Street"
                type="text"
                onChange={(e) => {
                  if (e.target.value.length <= 30) {
                    handleStreetChange(e);
                  }
                }}
                placeholder="Max length : 30 char"
                variant="outlined"
                value={street}
                // onChange={handleStreetChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                className="profile-input"
                label="Zip"
                type="text"
                placeholder="Max length : 10 char"
                variant="outlined"
                value={zip}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  if (numericValue.length <= 10) {
                    handleZipChange({ ...e, target: { ...e.target, value: numericValue } });
                  }
                  // if (e.target.value.length <= 10) {
                  //   handleZipChange(e);
                  // }
                }}
                InputProps={{
                  inputMode : 'numeric',
                  pattern: '[0-9]*',
                }}
                
              />
            </Grid>
          </Grid>

        </Grid>
      </form>

      {/* <img
        className="profile-img"
        src={imgUrl || `${API_URL}${profileData.profile_pic}`}
        alt="Profile"
      /> */}

      <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
        <Grid item xs={3} md={2} lg={1} style={{ margin: "20px" }}>
          <Button
            variant="contained"
            className="profile-submit"
            onClick={handleAddressUpdate}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <SnackbarComponent
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
}

export default Information;
