import React, { useState, useEffect, useDebugValue } from "react";
import Config, { IMAGES_URL } from "../../Services/Config";
import Navbar from "../../NavBar/NavBar";
import { Tabs, Tab, Box, Typography, InputAdornment, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Radio, RadioGroup, FormControl, FormLabel } from '@mui/material';
import PropTypes from 'prop-types';
import Button from "@mui/material/Button";
import Properties from "./Properties/Properties";
import Grid from '@mui/material/Grid';
import './Owner.css';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import Switch from '@mui/material/Switch';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import MapComponent from "../../SearchBar/Maps";
import Divider from "@mui/material/Divider";
import { Modal, Table } from "antd";
import dayjs from "dayjs";
import moment from 'moment';
//import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../Services/Config';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { format, startOfMonth, startOfYear } from 'date-fns';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { geolocated } from 'react-geolocated';
import { SearchIcon } from "lucide-react";
import { ControlCameraSharp } from "@mui/icons-material";
import { endOfMonth, addMonths, addYears } from 'date-fns';
import { useLanguage } from "../../LanguageContext";






const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#bb0129", // Color for the border when focused
    },
  },
  "& .MuiInputLabel-root": {
    // Style for the label
    color: "grey", // Default label color
    "&.Mui-focused": {
      color: "#bb0129", // Color for the label when focused
    },
  },
});

// const GeolocationComponent = ({ isGeolocationEnabled, coords }) => {
//   const [location, setLocation] = useState(null);

//   useEffect(() => {
//     if (isGeolocationEnabled && coords) {
//       setLocation({
//         latitude: coords.latitude,
//         longitude: coords.longitude,
//       });
//     }
//   }, [isGeolocationEnabled, coords]);

//   return (
//     <div>
//       {isGeolocationEnabled ? (
//         <div>
//           <p>Latitude: {location?.latitude}</p>
//           <p>Longitude: {location?.longitude}</p>
//         </div>
//       ) : (
//         <p>Geolocation is not enabled</p>
//       )}
//     </div>
//   );
// };

// export default geolocated({
//   positionOptions: {
//     enableHighAccuracy: false,
//   },
//   userDecisionTimeout: 5000,
// })(GeolocationComponent);
const handleDateFormat = (rentType) => {
  switch (rentType) {
    case 0:
      return 'daily'; // or return specific formatting options for daily
    case 1:
      return 'monthly'; // or return specific formatting options for monthly
    case 2:
      return 'monthly_sum_12'; // or return specific formatting options for 12-month sum
    default:
      return 'daily'; // Default fallback
  }
};
function TabPanel(props) {
  const { children, value,  ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      // id={`simple-tabpanel-${index}`}
      // aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {/* {value === index && ( */}
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      {/* // )} */}


    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  // index: PropTypes.number.isRequired,
  // value: PropTypes.number.isRequired,
};

const Owner = ({ id }) => {
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const [tabValue, setTabValue] = useState(0);
  const [tabRequestsValue, setRequestTabValue] = useState(0);
  const [properties, setProperties] = useState([]);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState([false]);
  const [pagination, setPagination] = useState([]);
  const [maxPagination, setMaxPagination] = useState([0]);
  const [open, setOpen] = React.useState(false);

  const [requestsOpen, setRequestsOpen] = useState(false);
  const [requestPropertyId ,setSelectedRequestPropertyId] = useState(0);
  const [requestsThisProperty,setPropertyRequests] = useState(null);
  const [textToShowAllRequestsProperty, setTextRequestProperty] = useState("");
  const [textToShowRequestsProperty, setTextRequestPendingProperty] = useState("");
  const [allRequestsThisProperty,setAllRequestsProperty] = useState(null);
  const { language, setLanguage } = useLanguage();

  const handleSetLang = (lang) => {
    setLanguage(lang);    
  }

  const CURRENCY_OPTIONS = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
    { value: 'GBP', label: 'GBP' },
    { value: 'JPY', label: 'JPY' },
    { value: 'AUD', label: 'AUD' },
    { value: 'CAD', label: 'CAD' },
    { value: 'CHF', label: 'CHF' },
    { value: 'CNY', label: 'CNY' },
    { value: 'SEK', label: 'SEK' },
    { value: 'NZD', label: 'NZD' },
    { value: 'LBP', label: 'LBP' }
  ];
  const [imagePreviews, setImagePreviews] = useState([]);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const [amenities, setAmenities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [images, setImageFiles] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // const googleMapsApiKey = 'AIzaSyDSH26MeYe3hxTjWd1QiCn6hsVd1Vvkplw';
  const googleMapsApiKey = 'AIzaSyA_UQmOHpuNcXt9T7DCRmbtzIWBtiWLe2E';
  const [formData, setFormData] = useState(null);

  const [invisbleButtons, setInvisibleButtons] = useState(false); 

  const [fileLoading,setFileLoading] = useState(false);
  const [requestLoading,setRequestLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [page, setPage] = useState(0);
  const [position, setPosition] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  
  const [monthlyRent, setMonthlyRent] = useState('');
  const [propertyData, setPropertyData] = useState({
    action: '',
    owner_id: '',
    name: '',
    price: '',
    short_description: '',
    description: '',
    category_id: '',
    type_id: '',
    min_duration: 1,
    location: '',
    longitude: '',
    latitude: '',
    occupancy_status_id: 0,
    instant_booking: 0,
    amenities: [],
    num_bedrooms: 1,
    num_beds: 1,
    num_guests: 1,
    dedicated_bathroom: 1,
    private_bathroom: 1,
    shared_bathroom: 1,
    street: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    rent_buy: 0, // 0 for Rent, 1 for Buy
    rent_type: 0, // 0 for Daily, 1 for Monthly, 2 for Yearly
    currency: 'USD',
    available_at: '',
    availability_ends_at: '',
    images: [],
    page: 1,
    monthlyRent: 1
});

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const userDetails = await Config.getUserDetails();
                // console.log('User details:', userDetails);

                // Extract user ID and set it in the propertyData state
                const userId = userDetails.data.id;
                setPropertyData(prevData => ({ ...prevData, owner_id: userId }));
                // console.log('User ID:', userId);
            } catch (error) {
                console.error('Failed to fetch user details:', error);
                // Handle the error as needed
            }
        };

        fetchUserDetails();
    }, []);
  let vrContentLocation = '';
  let videoLocation = '';
  const [availableAtError, setAvailableAtError] = useState("");
const [availabilityEndsAtError, setAvailabilityEndsAtError] = useState("");

  const [openDialog, setOpenDialog] = React.useState(false);
  const [nameError, setNameError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [shortDescError, setShortDescError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [minError, setMinError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [disabledDates, setDisabledDates] = useState([]);
  const [propertyFilter, setPropertyFilter] = useState('');

  const [propertyRequestFilter, setPropertyRequestFilter] = useState('');

  const [updatedDateRequestProperty, setUpdatedDateRequestProperty] = useState(null);
  const dateFormat = handleDateFormat(propertyData.rent_type);
  const[filterLoading, setFilterLoading] = useState(false);
  const [vrContentPreviews, setVRContentFiles] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const handleRentTypeChange = (event) => {
    const newRentType = parseInt(event.target.value, 10);
    
    setPropertyData({ ...propertyData, rent_type: newRentType });
    
    const today = new Date();
    if (newRentType === 0) { // Daily
        setFromMonthYear(today);
        setToMonthYear(today);
    } else if (newRentType === 1) { // Monthly
        setFromMonthYear(startOfMonth(today)); // Default to the 1st of the current month
        setToMonthYear(endOfMonth(addMonths(today, 1))); // Default to the end of the next month
    } else if (newRentType === 2) { // Yearly
        const startDate = startOfMonth(today); // Default to the 1st of the current month
        setFromMonthYear(startDate);
        
        // Default to 12 months later
        const endDate = endOfMonth(addMonths(startDate, 12));
        setToMonthYear(endDate);
    }
};



  useEffect(() => {
    const handleRequestLoading = () => {
      setRequestLoading(false);
    };
    handleRequestLoading();
  }, []);


  useEffect(() => {
    setTextRequestProperty("Loading...")
  },[]);

  useEffect(() => {
    setTextRequestPendingProperty("Loading...");
  },[])
  function logFormData(formData) {
    
  }
  const handleVRContentChange = async (event) => {
    handleFileLoading();
    const files = event.target.files;

    // console.log('File input change event:', event);

    if (files.length === 0) {
        console.log('No files selected.');
        setFileLoading(false);
        return;
    }

    const formData = new FormData();
    formData.append('vrContent', files[0]);

    // Log the formData entries after appending
    // for (let pair of formData.entries()) {
    //     console.log('FormData:', pair[0], ':', pair[1]);
    // }

    try {
        const uploadResult = await Config.uploadVRContent(formData);
        // console.log('Upload result:', uploadResult);
        const vrContentLocation = uploadResult.path; // Adjust as necessary
        setVRContentFiles((prevStrings) => [...prevStrings, vrContentLocation]);
    } catch (error) {
        console.error('Error uploading VR content:', error.response ? error.response.data : error.message);
        setIsSnackbarOpen(true);
          setSnackbarMessage(error.response.data.errors.vrContent.join(', '));

          setSnackbarSeverity("error");
    } finally {
        setFileLoading(false);
    }
};
const [fromDate, setFromDate] = useState(propertyData.available_at || null);
const [toDate, setToDate] = useState(propertyData.availability_ends_at || null);

const handleFromDateChange = (newValue) => {
  setFromDate(newValue);
  handleDateChange('available_at')(newValue);
};

const handleToDateChange = (newValue) => {
  setToDate(newValue);
  handleDateChange('availability_ends_at')(newValue);
};

const getDefaultDate = (baseDate) => {
  if (propertyData.rent_type === 1) {
    // Monthly: Default to the 1st of the current month
    return startOfMonth(baseDate);
  } else if (propertyData.rent_type === 2) {
    // Yearly: Default to the 1st of the current month, for year selection
    return startOfMonth(baseDate);
  }
  return baseDate; // Daily or default
};

const getViewsForRentType = () => {
  switch (propertyData.rent_type) {
    case 0:
      return ['day'];
    case 1:
      return ['month'];
    case 2:
      return ['year'];
    default:
      return ['day'];
  }
};

const views = getViewsForRentType();

const containerStyle = {
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  backgroundColor: '#f9f9f9'
};

const filterContainerStyle = {
  display: 'flex',
  alignItems: 'center', // Vertically align items
  gap: '10px', // Space between items
  marginBottom: '20px',
};

const labelStyle = {
  display: 'flex',
  flexDirection: 'column', // Stack label and input vertically
  marginBottom: '0', // Ensure no extra margin is added below the labels
};

const inputStyle = {
  marginTop: '5px', // Space between label and input
  width: '120px', // Fixed width to maintain consistency
};

const tableContainerStyle = {
  width: '100%', // Ensure table takes full available width
  overflowX: 'auto', // Allow horizontal scroll if needed
  tableLayout: 'fixed', // Fixed table layout to prevent resizing
};

const handleFilterStartDateChange = (e) => setSelectedStartDate(e.target.value);
const handleFilterEndDateChange = (e) => setSelectedEndDate(e.target.value);

const handle360VideoChange = async (event) => {
  handleFileLoading();
  const files = event.target.files;

  // console.log('File input change event:', event);

  if (files.length === 0) {
      console.log('No files selected.');
      setFileLoading(false);
      return;
  }

  const formData = new FormData();
  formData.append('360Video', files[0]);

 

  try {
      const uploadResult = await Config.upload360Video(formData);
      const videoLocation = uploadResult.path; // Adjust as necessary
      setVideoPreviews((prevStrings) => [...prevStrings, videoLocation]);
  } catch (error) {
      console.error('Error uploading 360 videos:', error.response ? error.response.data : error.message);
  } finally {
      setFileLoading(false);
  }
};
const filteredRequests = (allRequestsThisProperty || []).filter((reservationDetail) => {
  const reservationStart = new Date(reservationDetail.reservation.reservation_start);
  const reservationEnd = new Date(reservationDetail.reservation.reservation_end);
  const start = new Date(selectedStartDate);
  const end = new Date(selectedEndDate);

  return (!selectedStartDate || reservationStart <= start) && (!selectedEndDate || reservationEnd >= end);
});

  const clearVrForm = () => {
    setVRContentFiles([]); // Clear the VR content previews
  };
  
  const clearVideoForm = () => {
    setVideoPreviews([]); // Clear the 360-degree video previews
  };
  
  useEffect(()=> {
    setFilterLoading(false);
  },[]);


  useEffect(()=>{
    setInvisibleButtons(false);
  },[]);

  useEffect(() => {
    if(properties !== null && properties !== ""){
      setLoading(false);
    }
  },[properties]);



 
  const handleRadioChange = (event) => {
    setPropertyData({ ...propertyData, rent_buy: parseInt(event.target.value, 10) });
  };

  useEffect(() => {
    handlePropertyFilter();
    
  }, [propertyFilter]);


  useEffect(() => {
    handlePropertyRequestFilter();
  },[propertyRequestFilter]);


  useEffect(() => {
    setPropertyRequests(requestsThisProperty);
    setRequestLoading(false);
  },[requestsThisProperty]);

  const handleChangePropertyFilter = (e) =>  {
    setPropertyFilter(e.target.value)
  };


  const { RangePicker } = DatePicker;

  const [fromMonthYear, setFromMonthYear] = useState(propertyData.available_at ? new Date(propertyData.available_at) : null);
  const [toMonthYear, setToMonthYear] = useState(propertyData.availability_ends_at ? new Date(propertyData.availability_ends_at) : null);

  const handleMonthYearChange = (field) => (newDate) => {
    const startDate = startOfMonth(newDate);

    // Format date to 'yyyy-MM-dd'
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');

    if (propertyData.rent_type === 2) { // Yearly
        setFromMonthYear(startDate);
        setPropertyData({ ...propertyData, available_at: formattedStartDate });

        // Calculate end date for yearly rent: 12 months from the start date
        const calculatedEndDate = endOfMonth(addYears(startDate, 1)); // Add 1 year for yearly rent
        const formattedEndDate = format(calculatedEndDate, 'yyyy-MM-dd');
        setToMonthYear(calculatedEndDate);
        setPropertyData({ ...propertyData, availability_ends_at: formattedEndDate });
    } else if (propertyData.rent_type === 1) { // Monthly
        setFromMonthYear(startDate);
        setPropertyData({ ...propertyData, available_at: formattedStartDate });

        if (field === 'availability_ends_at') {
            const endDate = endOfMonth(newDate);
            const formattedEndDate = format(endDate, 'yyyy-MM-dd');
            setToMonthYear(endDate);
            setPropertyData({ ...propertyData, availability_ends_at: formattedEndDate });
        }
    } else { // Daily
        const formattedNewDate = format(newDate, 'yyyy-MM-dd');
        setPropertyData({ ...propertyData, [field]: formattedNewDate });
        if (field === 'available_at') {
            setFromMonthYear(newDate);
        } else {
            setToMonthYear(newDate);
        }
    }
};

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);


  const [isModalVisible, setIsModalVisible] = useState(true);


  const [rejectDialog, setRejectDialog] = useState(false);

  const handlePriceChange = (event) => {
    const value = event.target.value;
    // Ensure the value is numeric and does not exceed 10 characters
    if (/^\d*\.?\d{0,2}$/.test(value) && value.length <= 10) {
      setPropertyData((prevData) => ({
        ...prevData,
        price: value,
      }));
    }
  };
  
  const handleCloseRejectDialog = () => {
    setRejectDialog(false);
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFileLoading = () => {
    setFileLoading(true);
  }

  const handleRequestLoading = () => {
    setRequestLoading(true);
  }


  const handleDatePickerOpen = () => {
    setIsDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
  };
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (field) => (newValue) => {
    setPropertyData((prevData) => ({
      ...prevData,
      [field]: newValue ? newValue.toISOString() : null,
    }));
  };
  const handleShowRequest = (propertyId) => {
      setRequestsOpen(true);
      setSelectedRequestPropertyId(propertyId);
      setTextRequestProperty("Loading...");
      setTextRequestPendingProperty("Loading...");
  }  



  const handleTabRequestsChange = (event,newVal) => {
    setRequestTabValue(newVal);
  };  


  useEffect(() => {
    const handleRequestProperty = async () => {
      
        if(requestPropertyId !== null){
          try {
            const body = {
              "property_id" : requestPropertyId
            };
            const propertyRequests = await Config.getOwnerPropertyRequest(body);

            
            if(propertyRequests !== null){
              let resultRequests = Object.values(propertyRequests.data.msg);
              setPropertyRequests(resultRequests);
              
              if(propertyRequests.data.msg.length >= 1){
                let sizeOfReq = propertyRequests.data.msg.length;
                for(let i = 0 ; i < sizeOfReq ; i++){
                  let dateResStart = propertyRequests.data.msg[i].reservation_start;
                  let dateResEnd = propertyRequests.data.msg[i].reservation_end;
                }
              } else {
                setTextRequestPendingProperty("No Requests For This Property");
              }
            }
          } catch (error){
            console.error("Error request property details : ",error);
          } finally {
            setRequestLoading(false);
          }
        } else {

        }
      };


      const fetchAllRequests = async () => {
        try {
          
          if(requestPropertyId !== 0 && requestPropertyId !== null){
            const body = {
              "property_id" : requestPropertyId
            }
      
            const result = await Config.getOwnerAllRequestsThisProperty(body);
            if(result !== null){
  
                let resultAllRequests = Object.values(result.data.msg);
                setAllRequestsProperty(resultAllRequests);
                
                if(result.data.msg.length >= 1){
                  let sizeOfReq = result.data.msg.length;
                  for(let i = 0 ; i < sizeOfReq ; i++){
                    let dateResStart = result.data.msg[i].reservation_start;
                    let dateResEnd = result.data.msg[i].reservation_end;
                  }
                } else {
                  setTextRequestProperty("No Requests For This Property");
                }
                
              }
            }

        } catch (error) {
            console.error("Error when fetching all requests : ",error);
        }
        
      };
      handleRequestLoading();
      handleRequestProperty();
      fetchAllRequests();
      // setRequestLoading(false);
      // setSelectedRequestPropertyId(null);

  },[requestPropertyId]);


  const changeFormatUpdatedAtRequestProperty = (updatedAt) => {
    const originalDate = new Date(updatedAt);

    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(originalDate.getDate()).padStart(2, '0');

    const formattedDateString = `${year}-${month}-${day}`;

    return formattedDateString;
  }


  const isDateDisabled = (date) => {
    const dateString = dayjs(date).format("YYYY-MM-DD");
    const today = dayjs();

    const isReservedDate = disabledDates.includes(dateString);

    if (isReservedDate || dayjs(date).isBefore(today, "day")) {
      return true;
    }

    if (selectedStartDate) {
      const firstReservedDayAfterSelected = disabledDates.find((disabledDate) =>
        dayjs(disabledDate).isAfter(selectedStartDate)
      );

      if (firstReservedDayAfterSelected) {
        return dayjs(date).isAfter(dayjs(firstReservedDayAfterSelected), "day");
      }
      return false;
    }

    return false;
  };
  

  const cellStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  };

  

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px', // Rounded edges
    transition: 'transform 0.3s ease-out', // Animation for the transform property
  };


  const handleSetOpenDialog = () => {
    setOpenDialog(true);
  }
  const handleSetCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };


  const handlePropertyRequestFilter = () => {
    const fetchFilterRequests = async () => {
      try {

        if(propertyRequestFilter === ""){
          //should set pagination for requests
        } else {

          const body = {
            "property_id" : requestPropertyId,
            "user_request_name" : propertyRequestFilter,
          };

          const result = await Config.getRequestsPropertyFilter(body);


          if(result.data.status === "success"){
                let resultRequests = Object.values(result.data.reservation);
                setPropertyRequests(resultRequests);

          } else {


          }


        }

      } catch (error){
        console.error("Error when fetching filter request");
      }
    }

    fetchFilterRequests();

  }


  const handlePropertyFilter = () => {
    const fetchProperties = async () => {
      try {

          if(propertyFilter === ""){
              // console.log("Property search filter is empty");
              setPagination(1);
          } else {
            setPagination("All");
          }

          const result = await Config.getOwnerPropertiesFilter(propertyFilter);
          // console.log(result.data);
  
          if(result.data.status === "success"){
  
            //show filters property
  
            setProperties(result.data.properties);
            // setLoading(false);

            if(result.data.properties.length < 5){
              // console.log("properties : ",result.data.properties < 5);
              setInvisibleButtons(true);
            } else {
              setInvisibleButtons(false);
            }
  
          } else {
            //status not success
          } 
        

      } catch (error){
        console.error("Error fetching profile data : ",error);
      } finally {
        setFilterLoading(false);
      }
    }
      setFilterLoading(true);
      fetchProperties();
  }

  const handleClickPaginationLeft = () => {

      const fetchProperties = async () => {
        try {
          setLoading(true);
          const result = await Config.getOwnerPropertiesPagination(pagination,"left");
  
          if(result.data.status === "success"){
            setProperties(result.data.properties);
            setPagination(result.data.pagination);
            setLoading(false);
          }
          
        } catch (error) {
          console.error("Error fetching profile data:", error);
        }
      };

      if(pagination !== 1){
        fetchProperties();
      } 

  }

  
   const handleAcceptReservation = (requestId) => {
    const setAcceptReservation = async () => {
      try{
        const body = {
          "answer" : true,
          "reservation_id" : requestId,
        };
        const result = await Config.acceptReservation(body);
        // console.log("result : ",result);
        if(result.success){
          setIsSnackbarOpen(true);
          setSnackbarMessage(result.message);
          setSnackbarSeverity("success");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setIsSnackbarOpen(true);
          setSnackbarMessage(result.message);
          setSnackbarSeverity("error");
        }

      } catch (error){
        console.error("Error sending answer reservation, error : ",error);
      }

    // console.log("Request id : ",requestId);
    }

    setAcceptReservation();

   }

   


   const handleRejectReservation = (requestId) => {
    const setRejectReservation = async () => {
      try{
        const body = {
          "answer" : true,
          "reservation_id" : requestId,
        };
        const result = await Config.rejectReservation(body);
        // console.log("result : ",result);
        if(result.success){
          setIsSnackbarOpen(true);
          setSnackbarMessage(result.message);
          setSnackbarSeverity("success");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setIsSnackbarOpen(true);
          setSnackbarMessage(result.message);
          setSnackbarSeverity("error");
        }

      } catch (error){
        console.error("Error sending answer reservation, error : ",error);
      }

    // console.log("Request id : ",requestId);
    }

    setRejectReservation();

   }

  

  const handleClickPaginationRight = () => {
    // getOwnerPropertiesPagination

      const fetchProperties = async () => {
        try {
          setLoading(true);
          const result = await Config.getOwnerPropertiesPagination(pagination,"right");
  
          if(result.data.status === "success"){
            // console.log("data : ",result.data);
            setProperties(result.data.properties);
            setPagination(result.data.pagination);
            setMaxPagination(result.data.maxPagination);
            setLoading(false);
          } else {
            setLoading(false);
          }
          
        } catch (error) {
          console.error("Error fetching profile data:", error);
        }
      };

      if(pagination === maxPagination){
        
      } else {
        fetchProperties();
      }

  }


  const handleClose = () => {
    setOpen(false);
  }

  const handleCloseRequest = () => {
    setRequestsOpen(false);
    setRequestTabValue(0);
    if(allRequestsThisProperty){
      if(allRequestsThisProperty.length > 0){
        setAllRequestsProperty([]);
      }
    }
    

    if(requestsThisProperty.length > 0){
      setPropertyRequests([]);
    }
    // setTextRequestProperty("Loading...");
    // setTextRequestPendingProperty("Loading...");
    

  }


  const handleSetMsgsAsLoading = () => {
    setRequestsOpen(false);
    if(allRequestsThisProperty.length > 0){
      setAllRequestsProperty([]);
    }

    if(requestsThisProperty.length > 0){
      setPropertyRequests([]);
    }
    setTextRequestProperty("Loading...");
    setTextRequestPendingProperty("Loading...");
  }

  // const handleCloseRequest = () => {
  //   setRequestsOpen(false);
  // }

  const handleAddProperty = async () => {
    let hasError = false;
    if (propertyData.name.trim() === '' ) {
      // setNameError(true);
      setIsSnackbarOpen(true);
      setSnackbarMessage("Name is required.");
      setSnackbarSeverity("error");
      return;
    } else {
      // setNameError(false);
    }
    if (propertyData.rent_buy === 0) {
      // Validate the "Available At" field
      if (!propertyData.available_at) {
        setAvailableAtError("Available At is required.");
        hasError = true;
      } else {
        setAvailableAtError(""); // Clear error if valid
      }
  
      // Validate the "Availability Ends At" field
      if (!propertyData.availability_ends_at) {
        setAvailabilityEndsAtError("Availability Ends At is required.");
        hasError = true;
      } else {
        setAvailabilityEndsAtError(""); // Clear error if valid
      }
    } else {
      // If not renting, clear date-related errors
      setAvailableAtError("");
      setAvailabilityEndsAtError("");
    }
    if (propertyData.price.trim() === '' ) {
      // setPriceError(true);
      setIsSnackbarOpen(true);
      setSnackbarMessage("Price is required.");
      setSnackbarSeverity("error");
      return;
    } else {
      // setPriceError(false);
    }

    if (propertyData.short_description.trim() === '' ) {
      // setShortDescError(true);
      setIsSnackbarOpen(true);
      setSnackbarMessage("Short Description is required.");
      setSnackbarSeverity("error");
      return;
    } else {
      // setShortDescError(false);
    }
  
    if (propertyData.description.trim() === '' ) {
      // setDescError(true);
      setIsSnackbarOpen(true);
      setSnackbarMessage("Description is required.");
      setSnackbarSeverity("error");
      return;
    } else {
      // setDescError(false);
    }

    if (propertyData.category_id === '' ) {
      // setCategoryError(true);
      setIsSnackbarOpen(true);
      setSnackbarMessage("Category is required.");
      setSnackbarSeverity("error");
      return;
    } else {
      // setCategoryError(false);
    }

    if (propertyData.type_id === '' ) {
      // setTypeError(true);
      setIsSnackbarOpen(true);
      setSnackbarMessage("Type is required.");
      setSnackbarSeverity("error");
      return;
    } else {
      // setTypeError(false);
    }

    if (propertyData.rent_buy === 0 && !propertyData.min_duration ) {
      // setMinError(true);
      setIsSnackbarOpen(true);
      setSnackbarMessage("Minimum Duration is required.");
      setSnackbarSeverity("error");
      return;
    } else {
      // setMinError(false);
    }

    if (propertyData.location.trim() === '' ) {
      // setLocationError(true);
      setIsSnackbarOpen(true);
      setSnackbarMessage("Location is required.");
      setSnackbarSeverity("error");
      return;
    } else {
      // setLocationError(false);
    }

    // if (images.length < 5) {
    //   setIsSnackbarOpen(true);
    //   setSnackbarMessage("A minimum of 5 images is required.");
    //   setSnackbarSeverity("error");
    //   return; // Stop execution if the minimum image requirement is not met
    // }


    if(propertyData.num_bedrooms === 0){
      setIsSnackbarOpen(true);
      setSnackbarMessage("A minimum of 1 bedroom is required.");
      setSnackbarSeverity("error");
      return; // Stop execution if the minimum number of bedrooms requirement is not met
    }

    if(propertyData.num_beds === 0){
      setIsSnackbarOpen(true);
      setSnackbarMessage("A minimum of 1 bed is required.");
      setSnackbarSeverity("error");
      return; // Stop execution if the minimum number of beds requirement is not met
    }

    if(propertyData.num_guests === 0){
      setIsSnackbarOpen(true);
      setSnackbarMessage("A minimum of 1 guest is required.");
      setSnackbarSeverity("error");
      return; // Stop execution if the minimum number of guests requirement is not met
    }

    if(propertyData.dedicated_bathroom === 0){
      setIsSnackbarOpen(true);
      setSnackbarMessage("A minimum of 1 dedicated bathroom is required.");
      setSnackbarSeverity("error");
      return; // Stop execution if the minimum number of dedicated bathroom requirement is not met
    }

    if(propertyData.shared_bathroom === 0){
      setIsSnackbarOpen(true);
      setSnackbarMessage("A minimum of 1 shared bathroom is required.");
      setSnackbarSeverity("error");
      return; // Stop execution if the minimum number of shared bathrooms requirement is not met
    }

    if(propertyData.private_bathroom === 0){
      setIsSnackbarOpen(true);
      setSnackbarMessage("A minimum of 1 private bathroom is required.");
      setSnackbarSeverity("error");
      return; // Stop execution if the minimum number of private bathroom requirement is not met
    }

    if(propertyData.longitude.length === 0){
      setIsSnackbarOpen(true);
      setSnackbarMessage("The longitude is required.");
      setSnackbarSeverity("error");
      return; // Stop execution if the longitude requirement is not met
    }

    if(propertyData.latitude.length === 0){
      setIsSnackbarOpen(true);
      setSnackbarMessage("The latitude is required.");
      setSnackbarSeverity("error");
      return; // Stop execution if the latitude requirement is not met
    }


    if(selectedAmenities.length === 0){
      setIsSnackbarOpen(true);
      setSnackbarMessage("The amenities are required.");
      setSnackbarSeverity("error");
      return; // Stop execution if the amenities requirement is not met
    }
  

    const formData = new FormData();

    Object.entries(propertyData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    // console.log("images before foreach",images);

    if(images.length < 5){
      setIsSnackbarOpen(true);
      setSnackbarMessage("The images are required.");
      setSnackbarSeverity("error");
      return; // Stop execution if the images requirement is not met
    } else {
      images.forEach((file, index) => {
        
        formData.append(`images[${index}]`, file.location);
      });
    }
    images.forEach((file, index) => {
        formData.append(`images[${index}]`, file.location);
    });

    if (vrContentPreviews) {
        formData.append('vr_url', vrContentPreviews);
    }

    if (videoPreviews) {
        formData.append('360_url', videoPreviews);
    }

    // Append new fields


   

    Config.addProperty(formData)
        .then((result) => {
            if (result.success) {
                setIsSnackbarOpen(true);
                setSnackbarMessage(result.message);
                setSnackbarSeverity("success");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                setIsSnackbarOpen(true);
                setSnackbarMessage(result.message);
                setSnackbarSeverity("error");
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            if (error.response && error.response.status === 422) {
                setIsSnackbarOpen(true);
                setSnackbarMessage("Validation error: " + error.response.data.message);
                setSnackbarSeverity("error");
            }
        });

   
  }

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };


  useEffect(() => {
    const fetchProperties = async () => {
      try {
        setLoading(true);
        const result = await Config.getOwnerProperties();

        if(result.data.status === "success"){
          setPagination(result.data.pagination);
          setProperties(result.data.properties);
          setLoading(false);
        }
        
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProperties();
  }, []);

  const fetchCategories = async () => {
    try {
      const data = await Config.getCategoriesDetails();

      if (Array.isArray(data)) {
        setCategories(data);
      } else {
        console.error('Invalid data structure for categories:', data);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const isValidNumber = (value) => {
    return !isNaN(value) && value !== '' && value !== null;
  };

  const fetchTypes = async () => {
    try {
      const data = await Config.getTypesDetails();

      if (Array.isArray(data)) {
        setTypes(data);
      } else {
        console.error('Invalid data structure for types:', data);
      }
    } catch (error) {
      console.error('Error fetching types:', error);
    }
  };

  useEffect(() => {
    fetchTypes();
    // console.log("num bedrooms",propertyData.num_bedrooms);
  }, []);
  
  

  const handleFileChange = async (event) => {
    handleFileLoading();
    const files = event.target.files;
    const formData = new FormData();
    const uploadPromises = [];

    // console.log("File length : ",files.length);
  
    for (let i = 0; i < files.length; i++) {
      formData.append(`image[${i}]`, files[i]); // Use unique key for each file
      // console.log(" ",i," files : ",files[i]);
      uploadPromises.push(await Config.uploadImgs(formData));
    }

    setFileLoading(false);

    // console.log("upload Promises",uploadPromises);
  
    try {
      const uploadResults = await Promise.all(uploadPromises);
      const imageLocations = uploadResults.map((data) => data);

      // console.log("image Locations",imageLocations);

      setImageFiles((prevStrings) => [...prevStrings, ...imageLocations]);
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  
    const previews = [...imagePreviews];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = (e) => {
        previews.push(e.target.result);
        if (previews.length === imagePreviews.length + files.length) {
          setImagePreviews(previews);
          // console.log("previews",previews);
        }
      };
      reader.readAsDataURL(files[i]);
    }
    // console.log("Selected images:", Array.from(files));

    // console.log("Images in final : ",images);

  };

  const clearForm = () => {
    setImagePreviews([]);
    document.getElementById('file-upload').value = '';
  };

  const fetchAmenities = async () => {
    try {
      const data = await Config.getAmenitiesDetails();

      if (Array.isArray(data)) {
        setAmenities(data);
      } else {
        console.error('Invalid data structure for amenities:', data);
      }
    } catch (error) {
      console.error('Error fetching amenities:', error);
    }
  };

  useEffect(() => {
    fetchAmenities();
  }, []);

  const handleAmenityChange = (e, amenity) => {
    const amenityId = amenity.id;
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedAmenities((prevSelectedAmenities) => [
        ...prevSelectedAmenities,
        amenityId,
      ]);
    } else {
      setSelectedAmenities((prevSelectedAmenities) =>
        prevSelectedAmenities.filter((selectedAmenityId) => selectedAmenityId !== amenityId)
      );
    }
  };

  useEffect(() => {
    // console.log('Selected Amenities:', selectedAmenities);
  }, [selectedAmenities]);

  const handleMinDurationChange = (e) => {
    const value = e.target.value.slice(0, 3);
    if (!isNaN(value) && value !== '') {
      setPropertyData({ ...propertyData, min_duration: value });
    }if(value === ''){
      setPropertyData({ ...propertyData, min_duration: 0 });
    }
  };

  const handleLocationChange = (e) => {
    const onlyLettersAndSpaces = /^[A-Za-z\s]+$/;

    if (onlyLettersAndSpaces.test(e.target.value) || e.target.value === '') {
      setPropertyData({ ...propertyData, location: e.target.value.slice(0, 50) });
    }
  };

  const handleLongitudeChange = (event) => {
    const newLongitude = event.target.value.slice(0, 50);

    if (!isNaN(newLongitude) || newLongitude === '' || newLongitude === '-') {
      setPropertyData((prevData)=>({...prevData, longitude: newLongitude}));
    }
  };


  const handleLatitudeChange = (event) => {
    const newLatitude = event.target.value.slice(0, 50);
    if(!isNaN(newLatitude) || newLatitude === '' || newLatitude === '-'){
      setPropertyData((prevData) => ({ ...prevData, latitude: newLatitude }));
    }
    
  };

  const handleLocationChangee = ({ latitude, longitude }) => {
    // console.log("lat",latitude,"long",longitude);
    setPropertyData({ ...propertyData,latitude:latitude,longitude: longitude });
  };

  const handleCityChange = (e) => {
    const onlyLettersAndSpaces = /^[A-Za-z\s]+$/;
  
    // Limit the input to 50 characters
    const trimmedValue = e.target.value.slice(0, 50);
  
    if (onlyLettersAndSpaces.test(trimmedValue) || trimmedValue === '') {
      setPropertyData({ ...propertyData, city: trimmedValue });
    }
  };

  const handleStateChange = (e) => {
    const onlyLettersAndSpaces = /^[A-Za-z\s]+$/;
  
    // Limit the input to 50 characters
    const trimmedValue = e.target.value.slice(0, 50);
  
    if (onlyLettersAndSpaces.test(trimmedValue) || trimmedValue === '') {
      setPropertyData({ ...propertyData, state: trimmedValue });
    }
  };

  const handleCountryChange = (e) => {
    const onlyLettersAndSpaces = /^[A-Za-z\s]+$/;
  
    // Limit the input to 50 characters
    const trimmedValue = e.target.value.slice(0, 50);
  
    if (onlyLettersAndSpaces.test(trimmedValue) || trimmedValue === '') {
      setPropertyData({ ...propertyData, country: trimmedValue });
    }
  };

  const handleZipCodeChange = (e) => {
    const value = e.target.value.slice(0,30);
    if (!isNaN(value) || value === '') {
      setPropertyData({ ...propertyData, zip_code: value });
    }
  };

  useEffect(() => {
    setPropertyData((prevPropertyData) => ({
      ...prevPropertyData,
      amenities: selectedAmenities,
    }));
  }, [selectedAmenities]);

  const blue = {
    100: '#daecff',
    200: '#b6daff',
    300: '#66b2ff',
    400: '#3399ff',
    500: '#007fff',
    600: '#0072e5',
    700: '#0059B2',
    800: '#004c99',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const StyledInputRoot = styled('div')(({ theme }) => `
    // font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  `);

  const StyledInput = styled('input')(({ theme }) => `
  font-size: 0.875rem;
  // font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'};
  border-radius: 8px;
  margin: 0 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 3rem;
  height: 1rem;
  text-align: center;
  
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  appearance: none;

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    outline: none;
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
  }

  &:focus-visible {
    outline: none;
  }
`);

  const StyledButton = styled('button')(({ theme }) => `
    // font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    line-height: 1.5;
    border: 1px solid;
    border-radius: 999px;
    border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    width: 26px;
    height: 26px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      cursor: pointer;
      background: ${theme.palette.mode === 'dark' ? blue[700] : blue[500]};
      border-color: ${theme.palette.mode === 'dark' ? blue[500] : blue[400]};
      color: ${grey[50]};
    }
  
    &:focus-visible {
      outline: 0;
    }
  
    &.increment {
      order: 1;
    }
  `);

  const handleDeleteImage = (indexToDelete) => {
    setImagePreviews((prevPreviews) => prevPreviews.filter((_, index) => index !== indexToDelete));
  };

  const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    const [value, setValue] = React.useState(props.value);

    React.useEffect(() => {
      setValue(props.value);
    }, [props.value]);

    const handleChange = (e) => {
      let inputValue = parseInt(e.target.value, 10);

      if (isNaN(inputValue)) {
        inputValue = 0;
      }

      inputValue = Math.min(Math.max(inputValue, props.min), props.max);
      setValue(inputValue);

      props.onChange && props.onChange(inputValue);
    };

    
   
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StyledButton className="decrement" onClick={props.onDecrement}>
          <RemoveIcon fontSize="small" />
        </StyledButton>
        <StyledInput
          type="number"
          min={props.min}
          max={props.max}
          value={value}
          onChange={handleChange}
        />
        <StyledButton className="increment" onClick={props.onIncrement}>
          <AddIcon fontSize="small" />
        </StyledButton>
      </div>
    );
  });

  return (
    <div>
      

      <Navbar openDialog={openDialog} handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog} handleChangeLanguage={handleSetLang} />
      
      <div className="owner-container">

      {/* <div>Loading...</div> */}
        {loading ?
        <div style={{cursor:'default'}}>Loading...</div> : 
        <div className="profile-item-container">
          <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
            
            
            <Grid container justifyContent="space-between" alignItems="center" style={{marginBottom:"10px"}}>
              <Tabs value={tabValue} aria-label="owner tabs example">
                <Tab label="Properties" />
                {/* <Tab label="Requests" /> */}
              </Tabs>
        
              
            {/* <Grid item xs={12} sm={10} md={4} lg={4}> */}



            <Box display="flex" alignItems="center" >
              <TextField
                id="properyNameSearch"
                type="text"
                label="Property Name"
                variant="outlined"
                // placeholder={nameError ? 'This field is required' : 'Property Name'}
                value={propertyFilter.value}
                width={'40%'}
                InputProps={{
                  startAdornment: (
                    <SearchIcon style={{ marginRight: '8px', color: 'gray' }} />
                  ),
                }}
                onChange={(e) => {
                  if (e.target.value.slice(0, 50) === 0) {
                    // setPagination(1);
                    // console.log("pagination should be 1");
                  } else {
                    setPropertyFilter(e.target.value.slice(0, 50));
                    
                  }
                }}
                // error={nameError}
                maxLength={50}
                placeholder="Property Name"
                autoComplete="off"
              />

              <Box display="flex" alignItems="center" marginLeft='10px'>
                <Typography variant="body1" style={{ marginRight: '10px', cursor: 'default' }}>
                  Page :
                </Typography>
                <Typography variant="body1">{pagination}</Typography>
              </Box>

              <Button
                    variant="contained"
                    style={{
                      height: "2.5rem",
                      backgroundColor: "rgb(187, 1, 41)",
                      marginLeft:"10px"
                    }}
                    onClick={handleClickOpen}
                  >
                    Add Property
                  </Button>


            </Box>            
            </Grid>
          </Box>

          <Dialog 
            maxWidth={maxWidth}
            open={requestsOpen}
            onClose={handleSetMsgsAsLoading}
            sx={{
              minWidth: '400px',
              width: 'auto',
            }}
          >

            <Grid container justifyContent="space-between" alignItems="center" style={{marginBottom:"10px"}}>
              {/* <Tabs value={tabRequestsValue} onChange={handleTabRequestsChange} aria-label="owner tabs example"> */}
              <Tabs value={tabRequestsValue} onChange={handleTabRequestsChange} aria-label="owner tabs example">
                <Tab label="Current Requests" />
                <Tab label="All Requests" />
              </Tabs>
              </Grid>


              <IconButton
                aria-label="close"
                onClick={handleCloseRequest}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>


              <DialogContent
                sx={{padding:'20px 5px'}}
              >

                <TextField
                  id="requestUserName"
                  type="text"
                  label="Request User Name"
                  variant="outlined"
                  width={'40%'}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon style={{ marginRight: '8px', color: 'gray',}} />
                    ),
                  }}
                  onChange={(e) => {
                    if (e.target.value.slice(0, 50) === 0) {
                      // setPagination(1);
                      // console.log("pagination should be 1");
                    } else {
                      setPropertyRequestFilter(e.target.value.slice(0,50));
                    }
                  }}
                  // error={nameError}
                  maxLength={50}
                  placeholder="User Name"
                  autoComplete="off"
                />

                { tabRequestsValue  === 0 ? (
                  <table>
                    <TableHead>

                      
                      <TableRow>
                        <TableCell className="small-cell">Res Num</TableCell>
                        <TableCell className="small-cell">Prop Num</TableCell>
                        <TableCell className="small-cell">Prop Name</TableCell>
                        <TableCell className="small-cell">Prop Location</TableCell>
                        <TableCell className="small-cell">Prop Image</TableCell>
                        <TableCell className="small-cell">Amount</TableCell>
                        <TableCell className="small-cell">From</TableCell>
                        <TableCell className="small-cell">To</TableCell>
                        <TableCell className="small-cell">User Name</TableCell>
                        <TableCell className="small-cell" colSpan={2}>Action</TableCell> 
                      </TableRow>
                    </TableHead>
                    




                    {requestsThisProperty !== null ? (
                        requestsThisProperty.length === 1 ? (
                      
                          <TableBody>
                            {requestsThisProperty.map((requestProperty) => (
                              <TableRow key={requestProperty.reservation.id} hover  style={{ cursor: 'default' }}>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.reservation.id}</TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.property.id}</TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.property.name}</TableCell>

                                
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.property.location}</TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>
                                  <img src={IMAGES_URL + (requestProperty.propertyImages[0]?.image_url || '')} alt={requestProperty.propertyImages[0].name} style={{ width: '100px', height: '75px', objectFit: "cover" }} />
                                </TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>$ {requestProperty.reservation.Amount}</TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.reservation.reservation_start}</TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.reservation.reservation_end}</TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.userReservation.name}</TableCell>
                                <TableCell>
                                  <Button
                                    variant="outlined"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // handleEditClick(property.id);
                                      handleAcceptReservation(requestProperty.reservation.id);
                                    }}
                                  >
                                    Accept
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="outlined"
                                    style={{ color: "#bb0129", borderColor: "#bb0129" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRejectReservation(requestProperty.reservation.id);
                                    }}
                                  >
                                    Reject
                                  </Button>
                                </TableCell>



                              </TableRow>
                            ))}
                          </TableBody>
                        ) : 

                        requestsThisProperty.length > 1 ? (
                          <TableBody>
                            {requestsThisProperty.map((requestProperty) => (
                              <TableRow key={requestProperty.reservation.id} hover style={{ cursor: 'default' }}>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.reservation.id}</TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.property.id}</TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.property.name}</TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.property.location}</TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>
                                  <img src={IMAGES_URL + (requestProperty.propertyImages[0]?.image_url || '')} alt={requestProperty.propertyImages[0].name} style={{ width: '100px', height: '75px', objectFit: "cover" }} />
                                </TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>$ {requestProperty.reservation.Amount}</TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.reservation.reservation_start}</TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.reservation.reservation_end}</TableCell>
                                <TableCell className="small-cell" style={{textAlign:'center'}}>{requestProperty.userReservation.name}</TableCell>
                                <TableCell>
                                  <Button
                                    variant="outlined"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // handleEditClick(property.id);
                                      handleAcceptReservation(requestProperty.reservation.id);
                                    }}
                                  >
                                    Accept
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="outlined"
                                    style={{ color: "#bb0129", borderColor: "#bb0129" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRejectReservation(requestProperty.reservation.id);
                                    }}
                                  >
                                    Reject
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : requestsThisProperty.length === 0 ? (
                          <TableBody>
                            <TableRow>
                              <TableCell className="small-cell" style={{textAlign:'center'}} colSpan={12}>{textToShowRequestsProperty}</TableCell>
                            </TableRow>
                          </TableBody>
                        ) : (
                          <TableBody>
                            <TableRow>
                              {/* <TableCell style={cellStyle} colSpan={12}>No Requests for this Property</TableCell> */}
                            </TableRow>
                          </TableBody>
                        )
                          
                        
                        
                        
                        
                       
                    ) : (
                      <div>Loading...</div>
                    )}




                </table>
                ) :  <>
                {/* Date Range Filter */}
                <div style={containerStyle}>
      <div style={filterContainerStyle}>
        <label style={labelStyle}>
          Start Date:
          <input
            type="date"
            value={selectedStartDate}
            onChange={handleFilterStartDateChange}
            style={inputStyle}
          />
        </label>
        <label style={{ ...labelStyle, marginLeft: '20px' }}>
          End Date:
          <input
            type="date"
            value={selectedEndDate}
            onChange={handleFilterEndDateChange}
            style={inputStyle}
          />
        </label>
      </div>
    </div>
    <div style={tableContainerStyle}>
                {/* Table */}
                {allRequestsThisProperty ? (
                  <table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Res Num</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Prop Num</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Prop Name</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Prop Location</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Prop Image</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Amount</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>From</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>To</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>User Name</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Result taken</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Result</TableCell>
                      </TableRow>
                    </TableHead>
                    
                    {filteredRequests.length > 0 ? (
                      <TableBody>
                        {filteredRequests.map((requestDetail) => (
                          <TableRow key={requestDetail.reservation.id} hover style={{ cursor: 'default' }}>
                            <TableCell style={cellStyle}>{requestDetail.reservation.id}</TableCell>
                            <TableCell style={cellStyle}>{requestDetail.property.id}</TableCell>
                            <TableCell style={cellStyle}>{requestDetail.property.name}</TableCell>
                            <TableCell style={cellStyle}>{requestDetail.property.location}</TableCell>
                            <TableCell style={cellStyle}>
                              <img 
                                src={IMAGES_URL + (requestDetail.propertyImages[0]?.image_url || '')} 
                                alt={requestDetail.propertyImages[0]?.name || 'Property Image'} 
                                style={{ width: '100px', height: '75px', objectFit: "cover" }} 
                              />
                            </TableCell>
                            <TableCell style={cellStyle}>$ {requestDetail.reservation.Amount}</TableCell>
                            <TableCell style={cellStyle}>{requestDetail.reservation.reservation_start}</TableCell>
                            <TableCell style={cellStyle}>{requestDetail.reservation.reservation_end}</TableCell>
                            <TableCell style={cellStyle}>{requestDetail.userReservation.name}</TableCell>
                            <TableCell style={cellStyle}>{changeFormatUpdatedAtRequestProperty(requestDetail.userReservation.updated_at)}</TableCell>
                            <TableCell style={cellStyle}>{requestDetail.reservation.accepted === 1 ? "Accepted" : requestDetail.reservation.accepted === 2 ? "Rejected" : null}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell style={cellStyle} colSpan={12}>{textToShowAllRequestsProperty}</TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </table>
                ) : (
                  <table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Res Num</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Prop Num</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Prop Name</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Prop Location</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Prop Image</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Amount</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>From</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>To</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>User Name</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Result taken</TableCell>
                        <TableCell style={{ ...cellStyle, textAlign: 'center' }}>Result</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={cellStyle} colSpan={12}>{textToShowAllRequestsProperty}</TableCell>
                      </TableRow>
                    </TableBody>
                  </table>
                )}
                </div>
              </>
}

                

                </DialogContent>




          </Dialog>




          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            sx={{ minWidth: '400px' }}

          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Add a New Property
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>


            <DialogContent dividers>

              {/* <ToggleButtonGroup
                color="primary"
                value={propertyData.action}
                exclusive
                onChange={handleChangeToggleButton}
                aria-label="Platform"
                sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: "2%" }}
              >
                <ToggleButton
                  value="Rent"
                  sx={{
                    width: '50%',
                    '@media (max-width: 600px)': { width: '100%' },
                    '&.Mui-selected': {
                      backgroundColor: 'black',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    },
                  }}
                >
                  Rent
                </ToggleButton>
                <ToggleButton
                  value="Sell"
                  sx={{
                    width: '50%',
                    '@media (max-width: 600px)': { width: '100%' },
                    '&.Mui-selected': {
                      backgroundColor: 'black',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    },
                  }}
                >
                  Sell
                </ToggleButton>
              </ToggleButtonGroup> */}

              
              <p style={{ fontStyle: 'italic', marginBottom: '3%' }}>Please fill up the form to add a property. Fields marked with * are required.</p>

              <Grid container spacing={2} alignItems="center" justifyContent="center">
      {/* Name Field */}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          type="text"
          label="Name"
          placeholder="Max Length : 50 char"
          variant="outlined"
          fullWidth
          value={propertyData.name}
          onChange={(e) => setPropertyData({ ...propertyData, name: e.target.value.slice(0, 50) })}
          required
          inputProps={{ maxLength: 50 }}
        />
      </Grid>

      {/* Wrapper for Price and Currency */}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid container spacing={2}>
          {/* Price Field */}
          <Grid item xs={12} sm={6} md={8} lg={8}>
    <TextField
      type="text"  // Use "text" instead of "tel" for better control in some cases
      label="Price"
      variant="outlined"
      fullWidth
      value={propertyData.price}
      required
      placeholder="Max Length: 10 char"
      onChange={handlePriceChange}
      inputProps={{ maxLength: 10 }}  // Enforce max length
    />
  </Grid>

          {/* Currency Selector */}
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Currency</InputLabel>
              <Select
                value={propertyData.currency}
                onChange={(e) => setPropertyData({ ...propertyData, currency: e.target.value })}
                label="Currency"
              >
                {CURRENCY_OPTIONS.map((currency) => (
                  <MenuItem key={currency.value} value={currency.value}>
                    {currency.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    style={{ width: '100%', marginBottom: "3%" }}
                    type="text"
                    label="Short Description"
                    // placeholder={shortDescError ? 'This field is required' : 'Write a short description about your property...'}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={propertyData.short_description}
                    onChange={(e) => setPropertyData({ ...propertyData, short_description: e.target.value.slice(0, 200) })}
                    required
                    // error={shortDescError}
                    placeholder="Max Length : 200 char"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <FormLabel component="legend">Property Type</FormLabel>
          <RadioGroup
            row
            aria-label="property-type"
            name="property-type"
            value={propertyData.rent_buy}
            onChange={handleRadioChange}
          >
            <FormControlLabel value={1} control={<Radio />} label="Buy" />
            <FormControlLabel value={0} control={<Radio />} label="Rent" />
          </RadioGroup>
        </FormControl>
         {propertyData.rent_buy === 0 && (
    <Grid item lg={6} md={6} sm={12} xs={12}>
      <FormControl component="fieldset" style={{ width: '100%' }}>
        <FormLabel component="legend">Rent Type</FormLabel>
        <RadioGroup
          row
          aria-label="rent-type"
          name="rent-type"
          value={propertyData.rent_type}
          onChange={handleRentTypeChange}
        >
          <FormControlLabel value={0} control={<Radio />} label="Daily" />
          <FormControlLabel value={1} control={<Radio />} label="Monthly" />
          <FormControlLabel value={2} control={<Radio />} label="Yearly" />
        </RadioGroup>
      </FormControl>
    </Grid>
  )}
        
      </Grid>
      {/* {propertyData.rent_buy === 0 && (
         <LocalizationProvider dateAdapter={AdapterDateFns}>
         <Grid container justifyContent="flex-end">
           <Grid item>
             <Grid container spacing={1} alignItems="center" style={{ marginLeft: 'auto' }}>
               {/* From Date Picker /}
               {propertyData.rent_buy === 0 && (
                 <Grid item>
                   <DatePicker
                     views={propertyData.rent_type === 0 ? ['day'] : ['year', 'month']} // Daily: full date; Monthly/Yearly: month and year
                     label='Available From'
                     value={fromMonthYear}
                     onChange={handleMonthYearChange('available_at')}
                     renderInput={(params) => (
                       <TextField
                         {...params}
                         style={{ width: '150px' }} // Fixed width for consistent alignment
                         margin="normal"
                         variant="outlined"
                         required={propertyData.rent_buy === 0}
                         error={Boolean(availableAtError)}
                         helperText={availableAtError}
                       />
                     )}
                   />
                 </Grid>
               )}
   
               {/* To Date Picker /}
               {(propertyData.rent_buy === 0 && (propertyData.rent_type === 0 || propertyData.rent_type === 1 || propertyData.rent_type === 2)) && (
                 <Grid item>
                   <DatePicker
                     views={propertyData.rent_type === 0 ? ['day'] : ['year', 'month']} // Daily: full date; Monthly/Yearly: month and year
                     label='Available To'
                     value={toMonthYear}
                     onChange={handleMonthYearChange('availability_ends_at')}
                     renderInput={(params) => (
                       <TextField
                         {...params}
                         style={{ width: '150px' }} // Fixed width for consistent alignment
                         margin="normal"
                         variant="outlined"
                         required={propertyData.rent_buy === 0}
                         error={Boolean(availabilityEndsAtError)}
                         helperText={availabilityEndsAtError}
                       />
                     )}
                   />
                 </Grid>
               )}
             </Grid>
           </Grid>
         </Grid>
       </LocalizationProvider>
   
      )} */}

      <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    style={{ width: '100%', marginBottom: "3%" }}
                    type="text"
                    label="Description"
                    // placeholder={descError ? 'This field is required' : 'Write a description about your property...'}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={propertyData.description}
                    onChange={(e) => setPropertyData({ ...propertyData, description: e.target.value.slice(0, 200) })}
                    required
                    // error={descError}
                    placeholder="Max Length : 200 char"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <TextField
                    id="outlined-select-category"
                    select
                    label="Select Category"
                    defaultValue="Select a category"
                    // helperText={categoryError ? 'An option must be selected' : 'Please select a category'}
                    style={{ width: "100%" }}
                    value={propertyData.category_id}
                    onChange={(e) => setPropertyData({ ...propertyData, category_id: e.target.value })}
                    required
                    // error={categoryError}
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <TextField
                    id="outlined-select-type"
                    select
                    label="Select type"
                    defaultValue="Select a type"
                    // helperText={typeError ? 'An option must be selected' : 'Please select a type'}
                    style={{ width: "100%" }}
                    value={propertyData.type_id}
                    onChange={(e) => setPropertyData({ ...propertyData, type_id: e.target.value })}
                    required
                    // error={typeError}
                    placeholder="Max Length : 4 char"
                  >
                    {types.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {propertyData.rent_buy === 0 && (
  <Grid item lg={3} md={3} sm={6} xs={12}>
  <TextField
    id="min-duration"
    label="Minimum Duration"
    type="tel"
    multiline
    maxRows={4}
    value={propertyData.min_duration}
    onChange={handleMinDurationChange}
    style={{ width: '100%' }}
    InputProps={{
      inputMode: 'numeric',
      pattern: '[0-9]*',
    }}
    required={propertyData.rent_buy === 0} // Conditionally set required attribute
    placeholder="Max Length: 3 characters"
    error={Boolean(minError)}
    helperText={minError ? 'This field is required' : ''}
  />
</Grid>
)}


                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Location"
                    // placeholder={locationError ? 'This field is required' : 'Location'}
                    type="text"
                    multiline
                    maxRows={4}
                    value={propertyData.location}
                    onChange={handleLocationChange}
                    style={{ width: '100%' }}
                    required
                    // error={locationError}
                    placeholder="Max Length : 50 char"
                  />
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <FormGroup style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <FormControlLabel
                      label="Instant Booking"
                      control={
                        <Switch
                          checked={propertyData.instant_booking === 1}
                          onChange={() =>
                            setPropertyData((prevData) => ({
                              ...prevData,
                              instant_booking: prevData.instant_booking === 1 ? 0 : 1,
                            }))
                          }
                        />
                      }
                      style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "3%" }}
                    />
                  </FormGroup>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <FormGroup style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <FormControlLabel
                      label="Occupancy Status"
                      control={
                        <Switch
                          checked={propertyData.occupancy_status_id === 1}
                          onChange={() =>
                            setPropertyData((prevData) => ({
                              ...prevData,
                              occupancy_status_id: prevData.occupancy_status_id === 1 ? 0 : 1,
                            }))
                          }
                        />
                      }
                      style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "3%" }}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={3} md={2}>
                  
                </Grid>

              </Grid>
                    
              {(fileLoading &&  imagePreviews !== null) ?   (
                <div>
                  <div className="loading-overlay">
                    <div className="loader-icon">Loading...</div>
                  </div>


                  <Grid container style={{ marginTop: '2%' }}>
                      <div style={{
                        border: '1px solid #ddd',
                        padding: '10px',
                        textAlign: 'center',
                        width: '100%',
                        marginTop: '5px',
                        display: 'grid',
                        gap: '5px',
                        gridTemplateColumns: 'repeat(4, 1fr)', // Four equal-width columns
                      }}>
                      {imagePreviews.map((preview, index) => (
                        <div key={index} style={{
                          position: 'relative',
                          width: '100%',
                          boxSizing: 'border-box',
                          overflow: 'hidden',
                        }}>
                          <img
                            src={preview}
                            alt={`Preview ${index}`}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                          {/* <IconButton
                            style={{ position: 'absolute', top: '5px', right: '5px', color: 'black' }}
                            onClick={() => handleDeleteImage(index)}
                          >
                            <CloseIcon />
                          </IconButton> */}
                        </div>
                      ))}

                      <label htmlFor="file-upload" style={{ cursor: 'pointer', position: 'relative', width: '100%', paddingBottom: '100%', boxSizing: 'border-box', border: '2px dashed #aaa', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', boxSizing: 'border-box' }}>
                          <AddIcon fontSize="large" style={{ color: '#aaa' }} />
                        </div>
                        <input id="file-upload" type="file" hidden onChange={handleFileChange} accept=".pdf, .jpg, .jpeg, .png" multiple />
                      </label>

                </div>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '1rem', paddingRight: '20px' }}>
                    <p style={{fontStyle: 'italic'}}>A minimum of 5 images of your property is required</p>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="label"
                      >
                        Upload images
                        <input
                          id="file-upload"
                          type="file"
                          hidden
                          onChange={handleFileChange}
                          accept=".jpg, .jpeg, .png"
                          multiple
                        />
                      </Button>
                      <Button
                        variant="text"
                        onClick={clearForm}
                        style={{ color: 'grey' }}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                </Grid>
               
                <Grid item xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '1rem', paddingRight: '20px' }}>
                    <p style={{ fontStyle: 'italic' }}>Upload your VR content files</p>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Button variant="contained" color="primary" component="label">
                        Upload VR Content
                        <input
                          id="vr-content-upload"
                          type="file"
                          hidden
                          onChange={handleVRContentChange}
                          accept=".glb, .gltf, .unity, .fbx, .obj"
                          multiple={false}
                        />
                      </Button>
                      <Button variant="text" onClick={clearForm} style={{ color: 'grey' }}>
                        Clear
                      </Button>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '1rem', paddingRight: '20px' }}>
                    <p style={{ fontStyle: 'italic' }}>Upload your 360 video files</p>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Button variant="contained" color="primary" component="label">
                        Upload 360 Video
                        <input
                          id="360-video-upload"
                          type="file"
                          hidden
                          onChange={handle360VideoChange}
                          accept=".mp4"
                          multiple={false}
                        />
                      </Button>
                      <Button variant="text" onClick={clearForm} style={{ color: 'grey' }}>
                        Clear
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>

              </div>
              ) :
              //  () ? () : ()
              (
                <div>

              <Grid container style={{ marginTop: '2%' }}>
                <div style={{
                  border: '1px solid #ddd',
                  padding: '10px',
                  textAlign: 'center',
                  width: '100%',
                  marginTop: '5px',
                  display: 'grid',
                  gap: '5px',
                  gridTemplateColumns: 'repeat(4, 1fr)', // Four equal-width columns
                }}>
                  {imagePreviews.map((preview, index) => (
                    <div key={index} style={{
                      position: 'relative',
                      width: '100%',
                      boxSizing: 'border-box',
                      overflow: 'hidden',
                    }}>
                      <img
                        src={preview}
                        alt={`Preview ${index}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                      {/* <IconButton
                        style={{ position: 'absolute', top: '5px', right: '5px', color: 'black' }}
                        onClick={() => handleDeleteImage(index)}
                      >
                        <CloseIcon />
                      </IconButton> */}
                    </div>
                  ))}

                  <label htmlFor="file-upload" style={{ cursor: 'pointer', position: 'relative', width: '100%', paddingBottom: '100%', boxSizing: 'border-box', border: '2px dashed #aaa', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', boxSizing: 'border-box' }}>
                      <AddIcon fontSize="large" style={{ color: '#aaa' }} />
                    </div>
                    <input id="file-upload" type="file" hidden onChange={handleFileChange} accept=".pdf, .jpg, .jpeg, .png" multiple />
                    uploadImages
                  </label>

    </div>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '1rem', paddingRight: '20px' }}>
                    <p style={{fontStyle: 'italic'}}>A minimum of 5 images of your property is required</p>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="label"
                      >
                        Upload images
                        <input
                          id="file-upload"
                          type="file"
                          hidden
                          onChange={handleFileChange}
                          accept=".pdf, .jpg, .jpeg, .png"
                          multiple
                        />
                      </Button>
                      <Button
                        variant="text"
                        onClick={clearForm}
                        style={{ color: 'grey' }}
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                </Grid>
               
              </Grid>
              </div>
              
              )}
 <div>
  <Grid container style={{ marginTop: '2%' }}>
    <div style={{
      border: '1px solid #ddd',
      padding: '10px',
      textAlign: 'center',
      width: '100%',
      marginTop: '5px',
      display: 'grid',
      gap: '5px',
      gridTemplateColumns: 'repeat(4, 1fr)', // Four equal-width columns
    }}>
      {vrContentPreviews.map((preview, index) => (
        <div key={index} style={{
          position: 'relative',
          width: '100%',
          boxSizing: 'border-box',
          overflow: 'hidden',
        }}>
          {preview.endsWith('.glb') && (
            <div style={{ width: '100%', height: '100%', backgroundColor: '#f0f0f0' }}>
              <p>3D Model Preview (GLB)</p>
            </div>
          )}
          {preview.endsWith('.pdf') && (
            <embed src={preview} type="application/pdf" width="100%" height="100%" />
          )}
        </div>
      ))}

      <label htmlFor="file-upload-vr" style={{ cursor: 'pointer', position: 'relative', width: '100%', paddingBottom: '100%', boxSizing: 'border-box', border: '2px dashed #aaa', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', boxSizing: 'border-box' }}>
          <AddIcon fontSize="large" style={{ color: '#aaa' }} />
        </div>
        <input id="file-upload-vr" type="file" hidden onChange={handleVRContentChange} accept=".glb, .gltf, .fbx, .obj, .dae" multiple />
        Upload VR Content
      </label>
    </div>

    <Grid item lg={12} md={12} sm={12} xs={12}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '1rem', paddingRight: '20px' }}>
        <p style={{ fontStyle: 'italic' }}>Upload VR content (3D models)</p>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            variant="contained"
            color="primary"
            component="label"
          >
            Upload VR Content
            <input
              id="file-upload-vr"
              type="file"
              hidden
              onChange={handleVRContentChange}
              accept=".glb, .gltf, .fbx, .obj, .dae"
              multiple
            />
          </Button>
          <Button
            variant="text"
            onClick={clearVrForm}
            style={{ color: 'grey' }}
          >
            Clear
          </Button>
        </div>
      </div>
    </Grid>
  </Grid>
</div>

<div>
  <Grid container style={{ marginTop: '2%' }}>
    <div style={{
      border: '1px solid #ddd',
      padding: '10px',
      textAlign: 'center',
      width: '100%',
      marginTop: '5px',
      display: 'grid',
      gap: '5px',
      gridTemplateColumns: 'repeat(4, 1fr)', // Four equal-width columns
    }}>
      {videoPreviews.map((preview, index) => (
        <div key={index} style={{
          position: 'relative',
          width: '100%',
          boxSizing: 'border-box',
          overflow: 'hidden',
        }}>
          <video
            src={preview}
            controls
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </div>
      ))}

      <label htmlFor="file-upload-360" style={{ cursor: 'pointer', position: 'relative', width: '100%', paddingBottom: '100%', boxSizing: 'border-box', border: '2px dashed #aaa', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', boxSizing: 'border-box' }}>
          <AddIcon fontSize="large" style={{ color: '#aaa' }} />
        </div>
        <input id="file-upload-360" type="file" hidden onChange={handle360VideoChange} accept=".mp4" multiple />
        Upload 360-Degree Videos
      </label>
    </div>

    <Grid item lg={12} md={12} sm={12} xs={12}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '1rem', paddingRight: '20px' }}>
        <p style={{ fontStyle: 'italic' }}>Upload 360-degree videos (MP4 format only)</p>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            variant="contained"
            color="primary"
            component="label"
          >
            Upload 360-Degree Videos
            <input
              id="file-upload-360"
              type="file"
              hidden
              onChange={handle360VideoChange}
              accept=".mp4"
              multiple
            />
          </Button>
          <Button
            variant="text"
            onClick={clearVideoForm}
            style={{ color: 'grey' }}
          >
            Clear
          </Button>
        </div>
      </div>
    </Grid>
  </Grid>
</div>


              <Divider style={{marginTop: '2%'}}></Divider>


              <h1 className="ameneties">Ameneties</h1>
              <Grid container style={{ paddingLeft: "5%" }}>
                <FormGroup sx={{ display: 'flex' }}>
                  {amenities.length > 0 &&
                    amenities.map((category) => (
                      <React.Fragment key={category.id}>
                        <Typography variant="h6">{category.name}</Typography>
                        <FormGroup sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          {category.amenities.map((amenity) => (
                            <FormControlLabel
                              key={amenity.id}
                              control={
                                <Checkbox
                                  onChange={(e) => handleAmenityChange(e, amenity)}
                                  checked={selectedAmenities.includes(amenity.id)}
                                />
                              }
                              label={
                                <React.Fragment>
                                  <ListItem sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ListItemIcon>
                                      <img
                                        src={`${IMAGES_URL}${amenity.icon}`}
                                        alt={amenity.name}
                                        style={{ width: '24px', height: '24px' }}
                                      />
                                    </ListItemIcon>
                                    <Typography>{amenity.name}</Typography>
                                  </ListItem>
                                </React.Fragment>
                              }
                            />
                          ))}
                        </FormGroup>
                      </React.Fragment>
                    ))}
                </FormGroup>
              </Grid>

              <div className="divider-amenities"></div>

              <h1>Additional Information</h1>

              <div className="add-information">
                <Grid container style={{ marginTop: "5%" }}>
                  <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignItems: "center", display: "flex", marginBottom: "7%" }}>
                    <Typography variant="body1" style={{ marginLeft: "3%" }}>Number of Bedrooms</Typography>
                    <div style={{ marginLeft: 'auto', paddingRight: "10%" }}>
                      <NumberInput
                        aria-label="Quantity Input"
                        min={0}
                        max={99}
                        onChange={(value) => setPropertyData({ ...propertyData, num_bedrooms: value })}
                        value={isValidNumber(propertyData.num_bedrooms) ? (parseInt(propertyData.num_bedrooms) === 0 ? 1 : parseInt(propertyData.num_bedrooms)) : 1}
                        onIncrement={() => {
                          if (propertyData.num_bedrooms < 99) {
                            setPropertyData({ ...propertyData, num_bedrooms: propertyData.num_bedrooms + 1 });
                          }
                        }}
                        onDecrement={() => {

                          if(propertyData.num_bedrooms === 1 || propertyData.num_bedrooms === 0){
                            setPropertyData({ ...propertyData, num_bedrooms: 1 });
                          }

                          if (propertyData.num_bedrooms > 1) {
                            setPropertyData({ ...propertyData, num_bedrooms: propertyData.num_bedrooms - 1 });
                          }
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignItems: "center", display: "flex", marginBottom: "7%" }}>
                    <Typography variant="body1" style={{ marginLeft: "3%" }}>Number of Beds</Typography>
                    <div style={{ marginLeft: 'auto', paddingRight: "10%" }}>
                      <NumberInput
                        aria-label="Quantity Input"
                        min={0}
                        max={99}
                        onChange={(value) => setPropertyData({ ...propertyData, num_beds: value })}
                        value={isValidNumber(propertyData.num_beds) ? (parseInt(propertyData.num_beds) === 0 ? 1 : parseInt(propertyData.num_beds)) : 1}
                        onIncrement={() => {
                          if (propertyData.num_beds < 99) {
                            setPropertyData({ ...propertyData, num_beds: propertyData.num_beds + 1 });
                          }
                        }}
                        onDecrement={() => {

                          if(propertyData.num_beds === 1 || propertyData.num_beds === 0){
                            setPropertyData({ ...propertyData, num_beds: 1 });
                          }


                          if (propertyData.num_beds > 1) {
                            setPropertyData({ ...propertyData, num_beds: propertyData.num_beds - 1 });
                          }
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignItems: "center", display: "flex", marginBottom: "7%" }}>
                    <Typography variant="body1" style={{ marginLeft: "3%" }}>Number of Guests</Typography>
                    <div style={{ marginLeft: 'auto', paddingRight: "10%" }}>
                      <NumberInput
                        aria-label="Quantity Input"
                        min={0}
                        max={99}
                        onChange={(value) => setPropertyData({ ...propertyData, num_guests: value })}
                        value={isValidNumber(propertyData.num_guests) ?  (parseInt(propertyData.num_guests) === 0 ? 1 : parseInt(propertyData.num_guests)) : 1}
                        onIncrement={() => {
                          if (propertyData.num_guests < 99) {
                            setPropertyData({ ...propertyData, num_guests: propertyData.num_guests + 1 });
                          }
                        }}
                        onDecrement={() => {

                          if(propertyData.num_guests === 1 || propertyData.num_guests === 0){
                            setPropertyData({ ...propertyData, num_guests: 1 });
                          }


                          if (propertyData.num_guests > 1) {
                            setPropertyData({ ...propertyData, num_guests: propertyData.num_guests - 1 });
                          }
                        }}
                      />
                    </div>
                  </Grid>


                  <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignItems: "center", display: "flex", marginBottom: "7%" }}>
                    <Typography variant="body1" style={{ marginLeft: "3%" }}>Dedicated Bathrooms</Typography>
                    <div style={{ marginLeft: 'auto', paddingRight: "10%" }}>
                      <NumberInput
                        aria-label="Quantity Input"
                        min={0}
                        max={99}
                        onChange={(value) => setPropertyData({ ...propertyData, dedicated_bathroom: value })}
                        value={isValidNumber(propertyData.dedicated_bathroom) ? (parseInt(propertyData.dedicated_bathroom) === 0 ? 1 : parseInt(propertyData.dedicated_bathroom)) : 1}
                        onIncrement={() => {
                          if (propertyData.dedicated_bathroom < 99) {
                            setPropertyData({ ...propertyData, dedicated_bathroom: propertyData.dedicated_bathroom + 1 });
                          }
                        }}
                        onDecrement={() => {

                          if(propertyData.dedicated_bathroom === 1 || propertyData.dedicated_bathroom === 0){
                            setPropertyData({ ...propertyData, dedicated_bathroom: 1 });
                          }


                          if (propertyData.dedicated_bathroom > 1) {
                            setPropertyData({ ...propertyData, dedicated_bathroom: propertyData.dedicated_bathroom - 1 });
                          }
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignItems: "center", display: "flex", marginBottom: "7%" }}>
                    <Typography variant="body1" style={{ marginLeft: "3%" }}>Shared Bathrooms</Typography>
                    <div style={{ marginLeft: 'auto', paddingRight: "10%" }}>
                      <NumberInput
                        aria-label="Quantity Input"
                        min={0}
                        max={99}
                        onChange={(value) => setPropertyData({ ...propertyData, shared_bathroom: value })}
                        value={isValidNumber(propertyData.shared_bathroom) ? (parseInt(propertyData.shared_bathroom) === 0 ? 1 : parseInt(propertyData.shared_bathroom)) : 1}
                        onIncrement={() => {
                          if (propertyData.shared_bathroom < 99) {
                            setPropertyData({ ...propertyData, shared_bathroom: propertyData.shared_bathroom + 1 });
                          }
                        }}
                        onDecrement={() => {

                          if(propertyData.shared_bathroom === 1 || propertyData.shared_bathroom === 0){
                            setPropertyData({ ...propertyData, shared_bathroom: 1 });
                          }

                          if (propertyData.shared_bathroom > 1) {
                            setPropertyData({ ...propertyData, shared_bathroom: propertyData.shared_bathroom - 1 });
                          }
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12} style={{ alignItems: "center", display: "flex", marginBottom: "7%" }}>
                    <Typography variant="body1" style={{ marginLeft: "3%" }}>Private Bathrooms</Typography>
                    <div style={{ marginLeft: 'auto', paddingRight: "10%" }}>
                      <NumberInput
                        aria-label="Quantity Input"
                        min={0}
                        max={99}
                        onChange={(value) => {
                          setPropertyData({ ...propertyData, private_bathroom: value });
                          // console.log(value);
                          }}
                        value={isValidNumber(propertyData.private_bathroom) ? (parseInt(propertyData.private_bathroom) === 0 ? 1 : parseInt(propertyData.private_bathroom)) : 1}
                        onIncrement={() => {

                          if (propertyData.private_bathroom < 99) {
                            setPropertyData({ ...propertyData, private_bathroom: propertyData.private_bathroom + 1 });
                          }
                        }}
                        onDecrement={() => {

                          if(propertyData.private_bathroom === 1 || propertyData.private_bathroom === 0){
                            setPropertyData({ ...propertyData, private_bathroom: 1 });
                          }

                          if (propertyData.private_bathroom > 1) {
                            setPropertyData({ ...propertyData, private_bathroom: propertyData.private_bathroom - 1 });
                          }

                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className="divider-amenities"></div>

              <h1>Add Location</h1>

              <Grid container spacing={2} style={{ marginBottom: "3%" }}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Street"
                    placeholder="Max Length : 50 char"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={propertyData.street}
                    onChange={(e) => setPropertyData({ ...propertyData, street: e.target.value.slice(0,50) })}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    placeholder="Max Length : 50 char"
                    style={{ width: "100%" }}
                    value={propertyData.city}
                    onChange={handleCityChange}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="State"
                    variant="outlined"
                    placeholder="Max Length : 50 char"
                    style={{ width: "100%" }}
                    value={propertyData.state}
                    onChange={handleStateChange}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Country"
                    placeholder="Max Length : 50 char"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={propertyData.country}
                    onChange={handleCountryChange}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Zip Code"
                    variant="outlined"
                    style={{ width: "100%" }}
                    placeholder="Max Length : 30 char"
                    value={propertyData.zip_code}
                    onChange={handleZipCodeChange}
                  />
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <TextField
                    label="Longitude"
                    type="tel"
                    value={propertyData.longitude}
                    placeholder="Max Length : 50 char"
                    onChange={handleLongitudeChange}
                    style={{ width: '100%' }}
                    InputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    required
                  />

                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <TextField
                    label="Latitude"
                    placeholder="Max Length :50 char"
                    type="tel"
                    value={propertyData.latitude}
                    onChange={handleLatitudeChange}
                    style={{ width: '100%' }}
                    InputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                  />
                </Grid>
              </Grid>

              <MapComponent apiKey={googleMapsApiKey} onLocationChange={handleLocationChangee} />
              
            </DialogContent>





            <DialogActions>
            {tabValue === 0 ? <Button autoFocus variant="outlined" style={{backgroundColor:'rgb(187, 1, 41)',color:'white'}} onClick={handleAddProperty}>
                Add Property
              </Button> : null}
            </DialogActions>
          </Dialog>

          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleSnackbarClose}
              severity={snackbarSeverity}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>

          <TabPanel>
          {properties ? (
            requestLoading ? (
              <div>
                <div className="loading-overlay">
                  <div className="loader-icon">Loading ...</div>
                </div>
                <Properties
                  properties={properties}
                  formData={formData}
                  onClickRequest={(e) => {
                    // handleShowRequest(e);
                  }}
                />
              </div>
            ) : 

            filterLoading ? (
              <div className="loading-overlay">
                  <div className="loader-icon">Loading ...</div>
                </div>
            ) : (
              <Properties
              properties={properties}
              formData={formData}
              onClickRequest={(e) => {
                handleShowRequest(e);
              }}
            />)
              
              
            
          ) : (
            <div>Loading properties...</div>
            )
          }

                      <div style={{height:10}}>

                      </div>
                      
                      {invisbleButtons ? null : (
                      <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        <Button onClick={handleClickPaginationLeft}>Before</Button>
                        <Button onClick={handleClickPaginationRight}>Next</Button>
                    </div>)
                    }
          </TabPanel>
        </div>

        }

      </div>
    </div >
  );
};




export default Owner;
