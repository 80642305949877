import React, { useState, useEffect, useRef } from 'react';
import "./NotificationComponentCss.css";
const NotificationComponent = ({ onNotify }) => {
  const [audio] = useState(new Audio('https://dl.dropboxusercontent.com/s/h8pvqqol3ovyle8/tom.mp3'));
  const [showAlert, setShowAlert] = useState(true);
  const audioInitialized = useRef(false);

  useEffect(() => {
     
    audio.muted = true;

    audio.play().then(() => {
      // Already allowed
      setShowAlert(false);
      resetAudio();
    }).catch(() => {
      // Need user interaction
      audioInitialized.current = true;
    });
  }, [audio]);

  const resetAudio = () => {
    audio.pause();
    audio.currentTime = 0;
    audio.muted = false;
  };

  const handleAlertClick = (allowed) => {
    if (allowed) {
      audio.play().then(resetAudio);
    }
    onNotify("111111","waving");
    setShowAlert(false);
  };





  return (
    <div>
      {showAlert && (
        <div className="alertn">
          <p>This website would like to play sounds</p>
          <p className="buttonsn">
            <button onClick={() => handleAlertClick(false)} value="0" style={{ marginRight: '10px' }} >Block</button>
            
            <button onClick={() => handleAlertClick(true)} value="1">Allow</button>
          </p>
        </div>
      )}
    </div>
  );
};

export default NotificationComponent;
