import {
  CameraControls,
  ContactShadows,
  Environment,
  Text,
} from "@react-three/drei";
import { Suspense, useEffect, useRef, useState } from "react";
import { Avatar } from "./Avatar";

export const Experience = ({showAvatar,greet,latestMsg,taganimation}) => {
  const cameraControls = useRef();
  const [enableControlls,setEnableControlls] = useState(true);
  const [isAvatarLoaded, setIsAvatarLoaded] = useState(false);
  const timestamp = new Date().getMinutes() ;
  


  useEffect(() => {
    // cameraControls.current.setLookAt(0, 2, 5, 0, 1.5, 0);
    cameraControls.current.setLookAt(0, 2, 5, 0, 1.5, 0);
    // cameraControls.current.setLookAt(0, 0, 0, 0, 0, 0);
    // cameraControls.current.setLookAt(0,0,0,)

    if (isAvatarLoaded) {
      // Disable controls 1 seconds after the avatar is loaded
      const timeout = setTimeout(() => {
        setEnableControlls(false);
      }, 1000);

      // Cleanup timeout on unmount
      return () => clearTimeout(timeout);
    }

   

  }, [isAvatarLoaded]);



  return (
    <>
      <CameraControls ref={cameraControls} enabled={enableControlls}/>
      <Environment preset="sunset" />
      {/* Wrapping Dots into Suspense to prevent Blink when Troika/Font is loaded */}
      {/* <Suspense>
      </Suspense> */}
      {/* <Suspense fallback={null}>
        <Avatar greet={greet} latestMsg={latestMsg} onLoad={() => setIsAvatarLoaded(true)} position={[0, 0, 0]} cameraControls={cameraControls}  renderOrder={1} />
      </Suspense> */}
      {/* <Avatar greet={greet} latestMsg={latestMsg} onLoad={() => setIsAvatarLoaded(true)} position={[0, 0, 0]} cameraControls={cameraControls}  renderOrder={1} /> */}
      {showAvatar && (
        <Avatar greet={greet} latestMsg={latestMsg} taganimation={taganimation}  cameraControls={cameraControls} onLoad={() => setIsAvatarLoaded(true)}  />
      )}

      <ContactShadows opacity={0.7} />
    </>
  );
};
