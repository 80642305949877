import React, { useState, useEffect } from "react";
import Config from "../../../Services/Config";
import Navbar from "../../../NavBar/NavBar";
import "./Profile.css";
import Information from "./Information/Information";
import Address from "./Address/Adress";
import ChangePassword from "./ChangePassword/ChangePassword";
import { useLanguage } from "../../../LanguageContext";

const Profile = (props) => {
  const [profileData, setProfileData] = useState(null);
  const [addressData, setAddressData] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const { language, setLanguage } = useLanguage();

  const handleSetLang = (lang) => {
    setLanguage(lang);    
  }

  const handleSetOpenDialog = () => {
    setOpenDialog(true);
  }
  const handleSetCloseDialog = () => {
    setOpenDialog(false);
  }

  const { children, value, index, ...other } = props;

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const result = await Config.getUserDetails();
        if (result.success) {
          setProfileData(result.data);
          console.log(result.data);
        } else {
          console.log(result.message);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    const fetchAddressData = async () => {
      try {
        const result = await Config.getUserAddress();
        if (result.success) {
          setAddressData(result.data);
        } else {
          console.log(result.message);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchAddressData()
    fetchProfileData();
  }, []);

  if (!profileData) {
    return <div>Loading...</div>; // Or any other loading state representation
  }

  return (
    <div>
      <Navbar openDialog={openDialog} handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog} handleChangeLanguage={handleSetLang} />
      <div className="profile-container">
        <div className="profile-item-container">
          <Information profileData={profileData} />
        </div>
        <div className="profile-item-container">
          <Address addressData={addressData} />
        </div>
        <div className="profile-item-container last-container">
          <ChangePassword></ChangePassword>
        </div>
      </div>
    </div>
  );
};

export default Profile;
