import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import NavbarWithObserver from "../../NavBar/NavBar";
import Footer from "../../Footer/Footer";
import { useLanguage } from '../../LanguageContext';

const AboutUs = () => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const { language, setLanguage } = useLanguage();

  const handleSetLang = (lang) => {
    setLanguage(lang);    
  }
    
    const handleSetOpenDialog = () => {
        setOpenDialog(true);
    }
    const handleSetCloseDialog = () => {
        setOpenDialog(false);
    }
    return (
        <div className="about-us">
            <NavbarWithObserver openDialog={openDialog} handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog} handleChangeLanguage={handleSetLang} />

            <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
                <Typography variant="h4" gutterBottom>
                    About Us
                </Typography>
                <Typography variant="body1" paragraph>
                    Welcome to Divinests! We are a premier real estate company dedicated to helping our clients navigate the complex world of property buying, selling, and investing. Our team is made up of highly skilled professionals with extensive knowledge of the real estate market, committed to providing exceptional services and personalized support.
                </Typography>
                <Typography variant="body1" paragraph>
                    Established in [Year], we have continuously evolved to meet the diverse needs of our customers. Our mission is to deliver high-quality real estate solutions that exceed expectations and foster long-lasting relationships with our clients. Whether you are a first-time homebuyer or an experienced investor, we are here to guide you every step of the way.
                </Typography>
                <Typography variant="body1" paragraph>
                    At Divinests, we believe in innovation, integrity, and excellence. Our commitment to these values drives us to be a leader in the real estate industry. With a keen eye for market trends and a passion for finding the perfect properties, we strive to make your real estate experience seamless and successful.
                </Typography>
                <Typography variant="body1" paragraph>
                    Feel free to reach out to us for more information or to discuss how we can work together to achieve your real estate goals!
                </Typography>
            </Paper>

            <Footer />
        </div>
    );
};

export default AboutUs;
